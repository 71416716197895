module Shared.Domain.SensorType

open Shared.Dto.Dto
open Shared.DtoTypes.PhysicalSensor

let fromModel (model: SensorModel) =
    match model with
    | LHT65
    | LSN50v2_S31
    | S31_LS
    | S31_LB -> SensorType.Air
    | LSN50v2_Wind
    | SN50v3_LS_Wind
    | SN50v3_LB_Wind -> SensorType.WindAverage
    | LSN50v2_Rain_01mm
    | LSN50v2_Rain_02mm
    | SN50v3_LS_Rain_01mm
    | SN50v3_LS_Rain_02mm
    | SN50v3_LB_Rain_01mm
    | SN50v3_LB_Rain_02mm -> SensorType.RainFall
    | LSE01
    | SE01_LS
    | SE01_LB -> SensorType.Soil
    | LLMS01 -> SensorType.LeafletMoisture
    | LSPH01 -> SensorType.PH
    | WSC1L -> SensorType.WeatherStation
    | DDS20_LB
    | DDS20_LS -> SensorType.LiquidLevel
    | RS485_LB_ZFS_02
    | RS485_LS_ZFS_02 -> SensorType.SolarRadiation