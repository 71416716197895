module Client.Forms.RenamePeronosporaStation

open Client.Components
open Client.DomainTypes.Msg
open Fable.React
open Fable.React.Props
open Fulma
open Shared.DtoTypes.Page
open Shared.Infrastructure

type Model = {
    StationId: int
    Name: string option
    RequestRunning: bool
}

type FormResult =
    | Noop
    | CloseModal
    | Rename of MyPeronosporaStations.RenameRequest

let init (station: MyPeronosporaStations.ListingDto) = {
    StationId = station.Id
    Name = Some station.Name
    RequestRunning = false
}

let private form dispatch (model: Model) =
    form [] [
        Field.div [] [
            Label.label [] [ str "Name" ]
            Control.div [] [
                Input.text [
                    model.Name |> Option.defaultValue "" |> Input.Value
                    Input.OnChange(fun event ->
                        event.Value
                        |> String.toOption
                        |> RenameMyPeronosporaStationMsg.NameChanged
                        |> dispatch
                    )
                ]
            ]
        ]
    ]

let private tryCreateRenameRequest (model: Model) : MyPeronosporaStations.RenameRequest option =
    model.Name
    |> Option.map (fun name -> {
        StationId = model.StationId
        Name = name
    })

let private saveButton dispatch (model: Model) =
    let request = tryCreateRenameRequest model

    Button.button [
        Button.IsLoading model.RequestRunning
        Button.Color Color.IsLink

        match request with
        | Some request -> SubmitButton.onClick (fun _ -> dispatch (RenameMyPeronosporaStationMsg.RenameStation request))
        | None -> Button.Disabled true
    ] [ str "Speichern" ]

let view (dispatch: RenameMyPeronosporaStationMsg -> unit) (model: Model) =
    let closeModal = (fun _ -> dispatch RenameMyPeronosporaStationMsg.CloseModal)

    let headline = "Schorf-Station umbenennen"

    Modal.modal [ Modal.IsActive true ] [
        Modal.background [
            GenericOption.Props [ DOMAttr.OnClick closeModal ]
        ] []
        Modal.Card.card [] [
            Modal.Card.head [] [
                Modal.Card.title [] [ str headline ]
                Delete.delete [ Delete.OnClick closeModal ] []
            ]
            Modal.Card.body [] [ Content.content [] [ form dispatch model ] ]
            Modal.Card.foot [] [ saveButton dispatch model ]
        ]
    ]

let update (msg: RenameMyPeronosporaStationMsg) (model: Model) =
    match msg with
    | RenameMyPeronosporaStationMsg.NameChanged name -> { model with Name = name }, FormResult.Noop
    | RenameMyPeronosporaStationMsg.CloseModal -> model, FormResult.CloseModal
    | RenameMyPeronosporaStationMsg.RenameStation request ->
        { model with RequestRunning = true }, FormResult.Rename request