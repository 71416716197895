namespace Client.Forms

open Client.Infrastructure.Api
open Client.InfrastructureTypes
open Client.DomainTypes.Msg
open Elmish
open Fable.React
open Fable.React.Props
open Fulma
open Microsoft.FSharp.Core
open Shared.Dto
open Shared.DtoTypes
open Shared.Infrastructure
open Shared.Dto.Dto
open Shared
open Client
open Thoth.Elmish


[<RequireQualifiedAccess>]
module AlertForm =
    type Model = {
        Session: UserSession
        AvailableSensors: IdValue<MapSensorDto> list
        AvailableContactInfos: AlertDto.ContactInfo list
        Sensor: IdValue<MapSensorDto> option
        ContactInfo: AlertDto.ContactInfo option
        TemperatureThresholdType: AlertDto.TemperatureType
        PhoneAlertType: AlertDto.PhoneAlertType
        Threshold: string option
        RequestRunning: bool
    }

    type FormResult =
        | Noop
        | CloseModal
        | CloseAndRefresh

    let private thresholdTypeFromString typeString =
        match typeString with
        | "Feuchttemperatur" -> AlertDto.Wet
        | "Trockentemperatur" -> AlertDto.Dry
        | _ -> failwith "Unbekannter ThresholdTyp"

    let private phoneAlertTypeFromString alertType =
        match alertType with
        | "Anruf" -> AlertDto.Call
        | "SMS" -> AlertDto.SMS
        | _ -> failwith "That shouldn't happen"

    let init session sensors contactInfos = {
        Session = session
        AvailableSensors = sensors
        AvailableContactInfos = contactInfos
        Sensor = List.tryHead sensors
        ContactInfo = List.tryHead contactInfos
        TemperatureThresholdType = AlertDto.Dry
        PhoneAlertType = AlertDto.SMS
        Threshold = Some "2.0"
        RequestRunning = false
    }

    let private createAlert
        userId
        (sensor: IdValue<MapSensorDto>)
        (contactInfo: AlertDto.ContactInfo)
        thresholdType
        phoneAlertType
        threshold
        : AlertDto.NewAlert =

        {
            SensorId = sensor.Id
            ContactInfoId = contactInfo.Id
            TemperatureType = thresholdType
            UserId = userId
            PhoneAlertType = phoneAlertType
            Threshold = threshold
        }

    let private checkThresholdLimits (threshold: float) =
        if threshold >= -10.0 && threshold <= 4.0 then
            Some threshold
        else
            None

    let createAlertMsg userId maybeSensor maybeContactInfo thresholdType phoneAlertType maybeThreshold =
        Some createAlert
        |> Option.apply (Some userId)
        |> Option.apply maybeSensor
        |> Option.apply maybeContactInfo
        |> Option.apply (Some thresholdType)
        |> Option.apply (Some phoneAlertType)
        |> Option.apply (Option.bind checkThresholdLimits maybeThreshold)
        |> Option.map AlertFormMsg.CreateAlert

    let typeToOption (thresholdType: AlertDto.TemperatureType) =
        let string = AlertDto.thresholdTypeToString thresholdType

        option [ Value string ] [ str string ]

    let private thresholdTypeSelect (selected: AlertDto.TemperatureType) dispatch =
        let types = [ AlertDto.Wet; AlertDto.Dry ]
        let options = List.map typeToOption types

        Select.select [ Select.IsFullWidth ] [
            select
                [
                    DefaultValue(AlertDto.thresholdTypeToString selected)
                    OnChange(fun event -> dispatch (event.Value |> thresholdTypeFromString |> ThresholdTypeChanged))
                ]
                options
        ]

    let private sensorToOption (sensor: IdValue<MapSensorDto>) =
        let baseData = MapSensor.getBaseData sensor.Value
        let string = baseData.Name

        option [ Value baseData.Name ] [ str string ]

    let private sensorSelect
        (selected: IdValue<MapSensorDto> option)
        (availableSensors: IdValue<MapSensorDto> list)
        dispatch
        =
        let options = List.map sensorToOption availableSensors

        let findSensor name =
            availableSensors
            |> List.tryFind (fun sensor -> (MapSensor.getBaseData sensor.Value).Name = name)

        Select.select [ Select.IsFullWidth ] [
            select
                [
                    DefaultValue(
                        selected
                        |> Option.map (fun sensor -> (MapSensor.getBaseData sensor.Value).Name)
                        |> Option.defaultValue ""
                    )
                    OnChange(fun event -> dispatch (event.Value |> findSensor |> SensorChanged))
                ]
                options
        ]


    let private contactInfoToOption (info: AlertDto.ContactInfo) =
        let string = info.Name

        option [ Value string ] [ str string ]

    let private contactInfoSelect
        (selected: AlertDto.ContactInfo option)
        (availableContactInfos: AlertDto.ContactInfo list)
        dispatch
        =
        let options = List.map contactInfoToOption availableContactInfos

        let findContactInfo name =
            availableContactInfos
            |> List.tryFind (fun contactInfo -> contactInfo.Name = name)

        Select.select [ Select.IsFullWidth ] [
            select
                [
                    DefaultValue(
                        selected
                        |> Option.map (fun contactInfo -> contactInfo.Name)
                        |> Option.defaultValue ""
                    )
                    OnChange(fun event -> dispatch (event.Value |> findContactInfo |> ContactInfoChanged))
                ]
                options
        ]

    let alertTypeToOption (alertType: AlertDto.PhoneAlertType) =
        let string = AlertDto.phoneAlertTypeToString alertType

        option [ Value string ] [ str string ]

    let private alertTypeSelect (selected: AlertDto.PhoneAlertType) dispatch =
        let types = [ AlertDto.Call; AlertDto.SMS ]
        let options = List.map alertTypeToOption types

        Select.select [ Select.IsFullWidth ] [
            select
                [
                    DefaultValue(AlertDto.phoneAlertTypeToString selected)
                    OnChange(fun event -> dispatch (event.Value |> phoneAlertTypeFromString |> PhoneAlertTypeChanged))
                ]
                options
        ]

    let form dispatch (model: Model) =
        form [] [
            Field.div [] [
                Label.label [] [ str "Sensor" ]
                Control.div [ Control.IsExpanded ] [
                    sensorSelect model.Sensor model.AvailableSensors dispatch
                ]
            ]
            Field.div [] [
                Label.label [] [ str "Kontaktdaten" ]
                Control.div [ Control.IsExpanded ] [
                    contactInfoSelect model.ContactInfo model.AvailableContactInfos dispatch
                ]
            ]
            Field.div [] [
                Label.label [] [ str "Bewarnungsart" ]
                Control.div [ Control.IsExpanded ] [ alertTypeSelect model.PhoneAlertType dispatch ]
            ]
            Field.div [] [
                Label.label [] [ str "Verwendete Temperatur" ]
                Control.div [ Control.IsExpanded ] [
                    thresholdTypeSelect model.TemperatureThresholdType dispatch
                ]
            ]
            Field.div [] [
                Label.label [] [ str "Temperatur Grenzwert" ]
                Control.div [] [
                    Input.text [
                        model.Threshold |> Option.defaultValue "" |> Input.Value
                        Input.OnChange(fun event ->
                            event.Value |> String.toOption |> AlertFormMsg.ThresholdChanged |> dispatch
                        )
                    ]
                    Help.help [] [
                        str "Der Grenzwert muss zwischen +4˚C und -10˚C liegen"
                    ]
                ]
            ]
        ]

    let saveButton dispatch (model: Model) =
        let maybeOnClick =
            createAlertMsg
                model.Session.User.Id
                model.Sensor
                model.ContactInfo
                model.TemperatureThresholdType
                model.PhoneAlertType
                (Option.bind Inputs.toFloatOption model.Threshold)
            |> Option.map (fun msg -> Button.OnClick(fun _ -> dispatch msg))

        let buttonOptions = [
            Button.IsLoading model.RequestRunning
            Button.Color IsSuccess
            Button.Disabled(Option.isNone maybeOnClick)
        ]

        Button.button (List.addToListIfSome buttonOptions maybeOnClick) [ str "Erstellen" ]


    let view (dispatch: AlertFormMsg -> unit) (model: Model) =
        let closeModal = (fun _ -> dispatch AlertFormMsg.CloseModal)

        let headline = "Neue Bewarnung anlegen"

        Modal.modal [ Modal.IsActive true ] [
            Modal.background [
                GenericOption.Props [ DOMAttr.OnClick closeModal ]
            ] []
            Modal.Card.card [] [
                Modal.Card.head [] [
                    Modal.Card.title [] [ str headline ]
                    Delete.delete [ Delete.OnClick closeModal ] []
                ]
                Modal.Card.body [] [ Content.content [] [ form dispatch model ] ]
                Modal.Card.foot [] [ saveButton dispatch model ]
            ]
        ]

    let update (msg: AlertFormMsg) (model: Model) =
        match msg with
        | SensorChanged sensor -> { model with Sensor = sensor }, Cmd.none, FormResult.Noop
        | ThresholdTypeChanged thresholdType ->
            { model with TemperatureThresholdType = thresholdType }, Cmd.none, FormResult.Noop
        | PhoneAlertTypeChanged alertType -> { model with PhoneAlertType = alertType }, Cmd.none, FormResult.Noop
        | ContactInfoChanged contactInfo -> { model with ContactInfo = contactInfo }, Cmd.none, FormResult.Noop
        | ThresholdChanged threshold -> { model with Threshold = threshold }, Cmd.none, FormResult.Noop
        | CreateAlert alert ->
            let request = {
                SessionKey = model.Session.SessionKey
                Data = alert
            }

            { model with RequestRunning = true },
            Cmd.OfAsync.perform api.createAlert request AlertCreated,
            FormResult.Noop
        | AlertCreated response ->
            match response with
            | Ok _ ->
                let toastCmd = Toast.create "Speichern erfolgreich" |> Toast.success

                model, toastCmd, FormResult.CloseAndRefresh
            | Error _ ->
                let toastCmd = Toast.create "Fehler beim Speichern" |> Toast.error

                model, toastCmd, FormResult.Noop
        | AlertFormMsg.CloseModal -> model, Cmd.none, FormResult.CloseModal