module internal Client.Map.Symbol.LeafletWetness

open System
open Shared.Dto.MapSensorData
open Shared.DtoTypes.LeafletWetness

let getLeafletWetness (data: MapSensorData) =
    match data with
    | AirData airData ->
        let minutesSinceLastData = (DateTimeOffset.UtcNow - airData.Date).TotalMinutes

        if minutesSinceLastData <= 60.0 then
            Some airData.LeafletWetness
        else
            None
    | WeatherStationData weatherStationSensorData ->
        let minutesSinceLastData =
            (DateTimeOffset.UtcNow - weatherStationSensorData.Date).TotalMinutes

        if minutesSinceLastData <= 60.0 then
            Some weatherStationSensorData.LeafletWetness
        else
            None
    | _ -> None

let getLeafletMoisture (data: MapSensorData) =
    match data with
    | LeafletMoistureData moistureData ->
        let minutesSinceLastData = (DateTimeOffset.UtcNow - moistureData.Date).TotalMinutes

        if minutesSinceLastData <= 60.0 then
            Some moistureData.LeafletMoisture
        else
            None
    | _ -> None

let toImageSrc (wetness: LeafletWetness) =
    let file =
        match wetness with
        | Wet _ -> "BN100"
        | Dry -> "BN0"

    sprintf "/images/leaflet-wetness/%s.svg" file

let percentageToImageSrc (moisture: float) =
    let fileName =
        if moisture < 2. then "BN0"
        else if moisture < 5. then "BN50"
        else "BN100"

    sprintf "/images/leaflet-wetness/%s.svg" fileName