module Client.Map.Symbol.SoilMoisture

open Shared.Dto.MapSensorData

let toImageSrc (moisture: SoilMoistureSection) =
    let fileName =
        match moisture with
        | F1 -> "F1"
        | F2 -> "F2"
        | F3 -> "F3"
        | F4 -> "F4"
        | F5 -> "F5"
        | F6 -> "F6"
        | F7 -> "F7"
        | F8 -> "F8"
        | F9 -> "F9"

    sprintf "/images/soil-moisture/%s.svg" fileName

let getGroundMoisture (data: MapSensorData) : SoilMoistureSection option =
    match data with
    | GroundData groundData -> groundData.MoistureSection
    | _ -> None