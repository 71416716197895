module Shared.DtoTypes.DateRangeDto

open System
open Shared.Infrastructure

type DateRangeOption = {
    Id: string
    Label: string
    StartTime: DateTime -> DateTime
    EndTime: DateTime -> DateTime
}

let todayRange = {
    Id = "today"
    Label = "Heute"
    StartTime = fun currentTime -> currentTime |> DateTime.startOfDay
    EndTime = fun currentTime -> currentTime |> DateTime.endOfDay
}

let thisWeekRange = {
    Id = "this_week"
    Label = "Diese Woche"
    StartTime =
        fun currentTime ->
            let days = (int currentTime.DayOfWeek) - 1

            currentTime |> DateTime.removeDays days |> DateTime.startOfDay
    EndTime =
        fun currentTime ->
            let days = 7 - int currentTime.DayOfWeek

            currentTime |> DateTime.addDays days |> DateTime.endOfDay
}

let thisMonthRange = {
    Id = "this_month"
    Label = "Dieser Monat"
    StartTime = fun currentTime -> currentTime |> DateTime.removeDays (currentTime.Day - 1) |> DateTime.startOfDay
    EndTime =
        fun currentTime ->
            currentTime
            |> DateTime.addMonths 1
            |> (fun dt -> DateTime.removeDays dt.Day dt)
            |> DateTime.endOfDay
}

let thisYearRange = {
    Id = "this_year"
    Label = "Dieses Jahr"
    StartTime =
        fun currentTime ->
            currentTime
            |> DateTime.removeDays (currentTime.DayOfYear - 1)
            |> DateTime.startOfDay
    EndTime =
        fun currentTime ->
            currentTime
            |> DateTime.addDays (365 - currentTime.DayOfYear + 1)
            |> DateTime.endOfDay
}

let yesterdayRange = {
    Id = "yesterday"
    Label = "Gestern"
    StartTime = fun currentTime -> currentTime |> DateTime.removeDays 1 |> DateTime.startOfDay
    EndTime = fun currentTime -> currentTime |> DateTime.removeDays 1 |> DateTime.endOfDay
}

let lastWeekRange = {
    Id = "last_week"
    Label = "Letzte Woche"
    StartTime =
        fun currentTime ->
            let days = (int currentTime.DayOfWeek) - 1 + 7

            currentTime |> DateTime.removeDays days |> DateTime.startOfDay

    EndTime =
        fun currentTime ->
            let days = int currentTime.DayOfWeek

            currentTime |> DateTime.removeDays days |> DateTime.endOfDay
}

let lastMonthRange = {
    Id = "last_month"
    Label = "Letzter Monat"
    StartTime =
        fun currentTime ->
            currentTime
            |> DateTime.removeMonths 1
            |> (fun dt -> DateTime.removeDays (dt.Day - 1) dt)
            |> DateTime.startOfDay
    EndTime = fun currentTime -> currentTime |> DateTime.removeDays currentTime.Day |> DateTime.endOfDay
}

let lastYearRange = {
    Id = "last_year"
    Label = "Letztes Jahr"
    StartTime =
        fun currentTime ->
            currentTime
            |> DateTime.removeDays (currentTime.DayOfYear - 1)
            |> DateTime.removeYears 1
            |> DateTime.startOfDay
    EndTime = fun currentTime -> currentTime |> DateTime.removeDays (currentTime.DayOfYear) |> DateTime.endOfDay
}

let getRangeFromId (id: string) : DateRangeOption option =
    let availableRanges = [
        todayRange
        thisWeekRange
        thisMonthRange
        thisYearRange
        yesterdayRange
        lastWeekRange
        lastMonthRange
        lastYearRange
    ]

    List.tryFind (fun range -> range.Id = id) availableRanges