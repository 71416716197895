module Client.Page.User.PeronosporaData

open System
open Client.Infrastructure.Api
open Client.Components
open Client.Components.Graph.Graph.Peronospora
open Client.Domain
open Client.InfrastructureTypes
open Client.DomainTypes.Msg
open Elmish
open Fable.React
open Feliz
open Fulma
open Shared.Dto.Page
open Shared.Dto.Dto
open Shared.Infrastructure
open Client.Components.GraphCommon

type DataModel = {
    RequestRunning: bool
    DateRangePicker: DateRangePicker.Data
    Session: UserSession
    SensorId: int
    Station: PeronosporaData.StationDto
    GraphData: Loadable<PeronosporaGraph, unit>
}

type LoadingModel = {
    Session: UserSession
    SensorId: int
}

type Model = Loadable<DataModel, LoadingModel>

let init id (session: UserSession) : Model * Cmd<Msg> =
    let requestData = {
        SessionKey = session.SessionKey
        Data = id
    }

    let cmd =
        Cmd.OfAsync.perform
            api.getPeronosporaStation
            requestData
            (PeronosporaDataMsg.StationReceived >> Msg.PeronosporaData)

    Loadable.Loading(
        {
            Session = session
            SensorId = id
        }
    ),
    cmd

let private createRequestDataCommand (request: AuthenticatedRequest<PeronosporaData.DataRequest>) : Cmd<Msg> =
    Cmd.OfAsync.perform api.getPeronosporaData request (PeronosporaDataMsg.GraphDataReceived >> Msg.PeronosporaData)

let private stationResponseToDataModel
    (loadingModel: LoadingModel)
    (response: AuthenticatedResponse<PeronosporaData.StationResponse>)
    : Model * Cmd<Msg> =
    match response with
    | Ok station ->
        match station with
        | PeronosporaData.Data data ->
            let startTimestamp = DateTime.Now |> DateTime.addDays -1 |> DateTime.startOfDay
            let endTimestamp = DateTime.Now |> DateTime.startOfDay

            let requestData: AuthenticatedRequest<PeronosporaData.DataRequest> = {
                SessionKey = loadingModel.Session.SessionKey
                Data = {
                    StationId = loadingModel.SensorId
                    StartTimestamp = startTimestamp
                    EndTimestamp = endTimestamp |> DateTime.endOfDay
                }
            }

            Loadable.Data {
                RequestRunning = true
                DateRangePicker = DateRangePicker.initWithRange startTimestamp endTimestamp (TimeSpan.FromDays 31) false
                Session = loadingModel.Session
                SensorId = loadingModel.SensorId
                Station = data
                GraphData = Loadable.Loading()
            },
            createRequestDataCommand requestData
        | PeronosporaData.NotFound -> Loadable.Error "Schorf-Station nicht gefunden", Cmd.none
    | AuthenticatedResponse.Error authError ->
        match authError with
        | AuthErr Unauthenticated -> Loadable.Error "Sie sind nicht eingeloggt, bitte die Seite neu laden", Cmd.none
        | AuthErr Unauthorized -> Loadable.Error "Schorf-Station nicht gefunden", Cmd.none
        | CustomErr err -> Loadable.Error "Beim Laden der Schorf-Station ist ein Fehler aufgetreten", Cmd.none


let update (msg: PeronosporaDataMsg) (model: Model) : Model * Cmd<Msg> =
    match msg, model with
    | StationReceived stationResponse, Loadable.Loading loadingModel ->
        stationResponseToDataModel loadingModel stationResponse
    | GraphDataReceived dataResponse, Loadable.Data dataModel ->
        match dataResponse with
        | Ok(PeronosporaData.DataResponse.Data data) ->
            let graphData =
                if List.isEmpty data.Nodes then
                    PeronosporaGraph.NoData
                else
                    data |> createPeronosporaGraphData |> PeronosporaGraph.Data

            Loadable.Data {
                dataModel with
                    RequestRunning = false
                    GraphData = Loadable.Data graphData
            },
            Cmd.none
        | Ok(PeronosporaData.DataResponse.NotFound)
        | AuthenticatedResponse.Error _ ->
            Loadable.Data {
                dataModel with
                    GraphData = Loadable.Error "Ein Fehler ist beim Laden der Daten aufgetreten"
            },
            Cmd.none
    | GraphDateRangeChanged dates, Loadable.Data dataModel ->
        let newPickerModel = DateRangePicker.updateData dates dataModel.DateRangePicker

        Loadable.Data { dataModel with DateRangePicker = newPickerModel }, Cmd.none
    | RequestData request, Loadable.Data dataModel ->
        let authenticatedRequest = {
            SessionKey = dataModel.Session.SessionKey
            Data = request
        }

        Loadable.Data { dataModel with RequestRunning = true }, createRequestDataCommand authenticatedRequest
    | _, _ -> model, Cmd.none

let private graphWrappingView (graphs: ReactElement list) = div [] [ yield! graphs ]

let private boxHeader: ReactElement =
    Heading.h3 [
        Heading.Modifiers [
            Modifier.TextAlignment(Screen.All, TextAlignment.Option.Centered)
        ]
    ] [ str "Visualisierung der Daten" ]

let private createRequestData (stationId: int) (startDate: DateTime) (endDate: DateTime) : PeronosporaData.DataRequest = {
    StationId = stationId
    StartTimestamp = startDate
    EndTimestamp = endDate
}

let createEnabledButtonOptions dispatch (stationId: int) (startDate: DateTime) (endDate: DateTime) =
    let requestData = createRequestData stationId startDate (endDate.AddDays(1.))

    [
        Button.Disabled false
        Button.OnClick(fun _ -> dispatch (PeronosporaDataMsg.RequestData requestData |> PeronosporaData))
    ]

let private dateRangePicker dispatch (model: DataModel) =
    let buttonOptions =
        Option.map2
            (createEnabledButtonOptions dispatch model.SensorId)
            model.DateRangePicker.Start
            model.DateRangePicker.End
        |> Option.defaultValue [ Button.Disabled true ]

    let buttonOptions =
        List.append buttonOptions [
            Button.IsLink
            Button.IsLoading model.RequestRunning
        ]

    Column.column [ Column.Width(Screen.All, Column.IsFull) ] [
        Columns.columns [ Columns.IsVCentered ] [
            Column.column [ Column.Width(Screen.All, Column.IsNarrow) ] [ p [] [ str "Datumsbereich:" ] ]
            Column.column [] [
                DateRangePicker.view
                    "Von/Bis Datumsbereich auswählen"
                    ((PeronosporaDataMsg.GraphDateRangeChanged >> PeronosporaData) >> dispatch)
                    model.DateRangePicker
            ]
            Column.column [ Column.Width(Screen.All, Column.IsNarrow) ] [ Button.button buttonOptions [ str "Laden" ] ]
        ]
    ]

let private pageHeader (stationName: string) : ReactElement =
    Heading.h1 [
        Heading.Modifiers [
            Modifier.TextAlignment(Screen.All, TextAlignment.Centered)
        ]
    ] [ str stationName ]

let private pageLayout stationName content =
    Container.container [ Container.isFullWidth ] [
        pageHeader stationName
        Columns.columns [ Columns.IsMultiline ] [
            Column.column [ Column.Width(Screen.All, Column.IsFull) ] [ Box.box' [] content ]
        ]
    ]

let private graphsView (graphData: PeronosporaGraph) =
    match graphData with
    | PeronosporaGraph.NoData -> [
        createFullColumn (boxStringSubtitle "Es wurden keine Daten im ausgewählten Zeitraum gefunden")
      ]
    | PeronosporaGraph.Data data -> graphs data


let pageView dispatch (model: DataModel) =
    let boxContent = [
        boxHeader
        dateRangePicker dispatch model
        yield! (Loadable.listView graphsView model.GraphData)
    ]

    pageLayout model.Station.Name boxContent


let view dispatch (model: Model) = Loadable.view (pageView dispatch) model