module Client.Map.SensorPopup

open System
open Client.DomainTypes
open Client.DomainTypes.Msg
open Client.Domain.MeasuredValueString
open Client.Infrastructure
open Client.Map.Symbol
open Fable.React
open Fable.React.Props
open Fulma
open Fulma.Extensions.Wikiki
open Shared
open Shared.Dto.MapSensorData
open Shared.DtoTypes.MapSensorData
open Shared.DtoTypes.LeafletWetness
open Shared.Infrastructure

let private popupSecondColCls = "second-column"
let private popupMeasurementCls = "measurement-data"

let private iconCls = "icon"

let private signalStrengthCls = "signal-strength"

let private rainIntensityCls = "rain-intensity"

let private wideIconCls = "wide-icon"

let private leafletWetnessCls = "leaflet-wetness"

let private detailsButtonCls = "details-button"

let getSensorNameLinkColor (data: MapSensorData) =
    match data with
    | NoDataAvailable
    | NoPublicData -> "black"
    | AirData _ -> "#369fd9"
    | GroundData _ -> "#8f5c30"
    | RainFallData _ -> "#0033ab"
    | LeafletMoistureData _ -> "#4c70c4"
    | SoilPhData _ -> "#cc9933"
    | AverageWindSpeedData _ -> "#663399"
    | WeatherStationData _ -> "#E6821E"
    | LiquidLevelData _ -> "#4d4d4d"
    | SolarRadiationData _ -> "#8B8000"

let createDetailsSensorNameLink props (sensor: MapSensor) dispatch =
    let customProps = [
        sensor.Id
        |> Route.MySensSensor
        |> Clickable.onClickGoToRoute dispatch
        |> OnClick
        :> IHTMLProp
        Style [ Color(getSensorNameLinkColor sensor.Data) ] :> IHTMLProp
    ]

    a (List.append customProps props) [ str sensor.Name ]


type private AggregatedValue = {
    Label: string
    SubLabel: string
    Value: string
}

let private createAggregatedValueRow (index: int) (value: AggregatedValue) =
    let todayColumnContent = if index = 0 then [ str "Heute" ] else []

    let labelColumn = [
        str value.Label
        sub [] [ str value.SubLabel ]
        str ":"
    ]

    tr [] [
        td [] todayColumnContent
        td [] labelColumn
        td [ Class popupSecondColCls ] [ str value.Value ]
    ]

let private aggregatedValuesDailyPopup aggregatedValues =
    let rows = List.mapi createAggregatedValueRow aggregatedValues

    let table = table [ Style [ Width "100%" ] ] [ tbody [] rows ]

    tr [] [ td [ ColSpan 2 ] [ table ] ]

let private createAggregatedMinMaxTemperaturePopup minValue maxValue =
    let datedTemperatureString = DatedValue.toString temperatureToString

    [
        {
            Label = "T"
            SubLabel = "min"
            Value = datedTemperatureString minValue
        }
        {
            Label = "T"
            SubLabel = "max"
            Value = datedTemperatureString maxValue
        }
    ]

let private createAggregatedMinMaxHumidityPopup minValue maxValue =
    let datedPercentageString = DatedValue.toString percentageToString

    [
        {
            Label = "BF"
            SubLabel = "min"
            Value = datedPercentageString minValue
        }
        {
            Label = "BF"
            SubLabel = "max"
            Value = datedPercentageString maxValue
        }
    ]

let private createAggregatedMaxWindSpeedPopup (maxValue: DatedValue<float> option) =
    let datedWindSpeedString = DatedValue.toString windSpeedToString

    let value =
        match maxValue with
        | Some max -> datedWindSpeedString max
        | None -> "-"

    [
        {
            Label = "Wind"
            SubLabel = "max"
            Value = value
        }
    ]


let detailsButtonRow (sensor: MapSensor) dispatch =
    tr [] [
        td [ ColSpan 2; Class detailsButtonCls ] [
            Button.button [
                Button.Color Color.IsLink
                Button.OnClick(fun _ -> dispatch (Route.MySensSensor sensor.Id |> GoToRoute |> Global))
                Button.IsOutlined
            ] [ str "Detailseite" ]
        ]
    ]

let makeMarkerPopupHeader (sensor: MapSensor) dispatch =
    let factory =
        if sensor.DetailsAllowed then
            createDetailsSensorNameLink
        else
            fun props sensor _ -> span props [ str sensor.Name ]

    let nameProperties: IHTMLProp list = [ Class "popup-sensor-name" :> IHTMLProp ]

    let sensorName = factory nameProperties sensor dispatch


    tr [ Class "header" ] [
        td [] [ sensorName ]
        td [ Class popupSecondColCls ] [ str (DateTime.toDateString DateTime.Now) ]
    ]

let private createPopupSymbol cls toolTip src =
    div [
        classList [
            Tooltip.ClassName, true
            iconCls, true
            cls, true
        ]
        Tooltip.dataTooltip toolTip
    ] [ img [ Src src ] ]

let makeMarkerSymbolRow (data: MapSensorData) =
    let signalStrength = SignalStrength.fromMapSensorData data

    let leafletWetness = LeafletWetness.getLeafletWetness data

    let leafletMoisture = LeafletWetness.getLeafletMoisture data

    let rainIntensity = RainFall.getRainFallIntensity data

    let averageWindSpeed = AverageWindSpeed.mapSensorDataToAverageWindSpeed data

    let soilPh = SoilPh.toSoilPhData data

    let soilMoisture = SoilMoisture.getGroundMoisture data

    let radiationIntensity = RadiationIntensity.getRadiationIntensity data

    let leftContent =
        List.choose id [
            signalStrength
            |> Option.map SignalStrength.toImageSrc
            |> Option.map (createPopupSymbol signalStrengthCls "Empfangsstärke")
            (getBatteryLevel data)
            |> Option.map BatteryLevel.toImageSrc
            |> Option.map (createPopupSymbol signalStrengthCls "Batterie")
        ]

    let leafletWetnessTooltipText =
        match leafletWetness with
        | Some(Wet _) -> "Blatt nass"
        | Some Dry -> "Blatt trocken"
        | None -> ""

    let rightContent =
        List.choose id [
            leafletWetness
            |> Option.map LeafletWetness.toImageSrc
            |> Option.map (createPopupSymbol leafletWetnessCls leafletWetnessTooltipText)
            leafletMoisture
            |> Option.map LeafletWetness.percentageToImageSrc
            |> Option.map (createPopupSymbol rainIntensityCls "Blattnässe")
            rainIntensity
            |> Option.map RainFall.toImageSrc
            |> Option.map (createPopupSymbol rainIntensityCls "Regenmenge")
            averageWindSpeed
            |> Option.map AverageWindSpeed.getIconForWindSpeed
            |> Option.map (createPopupSymbol wideIconCls "Windgeschwindigkeit")
            soilPh
            |> Option.map SoilPh.toImageSrc
            |> Option.map (createPopupSymbol wideIconCls "PH-Wert")
            soilMoisture
            |> Option.map SoilMoisture.toImageSrc
            |> Option.map (createPopupSymbol leafletWetnessCls "Bodenfeuchtigkeit")
            radiationIntensity
            |> Option.map RadiationIntensity.toImageSrc
            |> Option.map (createPopupSymbol rainIntensityCls "Globalstrahlung")
        ]

    tr [] [
        td [] leftContent
        td [ Class popupSecondColCls ] rightContent
    ]

let popupUpdateRow date =
    let timeElapsed = (DateTime.Now - date) |> TimeSpan.toHumanReadable

    let timeElapsedToolTip =
        sprintf "%s (%s)" (DateTime.toDayMonthString date) (DateTime.toTimeString date)

    tr [] [
        td [] [ str "Aktualisiert:" ]
        td [
            classList [
                (popupSecondColCls, true)
                (Tooltip.ClassName, true)
            ]
            Tooltip.dataTooltip timeElapsedToolTip
        ] [ sprintf "vor %s" timeElapsed |> str ]
    ]

let popupDataRow label number =
    tr [] [
        td [] [ sprintf "%s:" label |> str ]
        td [
            classList [
                popupSecondColCls, true
                (popupMeasurementCls, true)
            ]
        ] [ str number ]
    ]

let private noPublicSensorDataPopup = [
    tr [] [
        td [ ColSpan 2 ] [
            str
                "Für diesen Sensor gibt es keine öffentlich sichtbaren Daten. Wenn du die Daten sehen möchtest, dann logge dich bitte ein"
        ]
    ]
]

let private airDataToPopup (data: AirSensorData) = [
    popupUpdateRow data.Date.LocalDateTime
    tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
    popupDataRow "Temperatur" (temperatureToString data.AirTemperature)
    popupDataRow "Rel. Luftfeuchte" (sprintf " %.2f %%" data.AirHumidity)
    popupDataRow "Feuchttemperatur" (temperatureToString data.HumidTemperature)
    popupDataRow "Taupunkt" (temperatureToString data.DewPoint)
    tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
    createAggregatedMinMaxTemperaturePopup data.MinAirTemperature data.MaxAirTemperature
    |> aggregatedValuesDailyPopup
]

let private groundDataToPopup (data: GroundSensorData) = [
    popupUpdateRow data.Date.LocalDateTime
    tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
    popupDataRow "Rel. Bodenfeuchte" (sprintf " %.2f %%" data.GroundHumidity)
    popupDataRow "Bodentemperatur" (temperatureToString data.GroundTemperature)
    popupDataRow "Leitfähigkeit" (conductivityToString data.SoilConductivity)
    tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
    createAggregatedMinMaxHumidityPopup data.MinGroundHumidity data.MaxGroundHumidity
    |> aggregatedValuesDailyPopup
]

let private rainFallDataToPopup (data: RainFallSensorData) =
    let maybeRainFallToString = Option.map rainFallToString >> Option.defaultValue "-"

    [
        popupUpdateRow data.Date.LocalDateTime
        tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
        popupDataRow "Regen aktuell" (rainFallToString data.CurrentRainFall)
        popupDataRow "Regen letzte Stunde" (maybeRainFallToString data.RainFallLastHour)
        popupDataRow "Regen letzte 24 Stunden" (maybeRainFallToString data.RainFallLast24h)
    ]

let private leafletMoistureDataToPopup (data: LeafletMoistureSensorData) = [
    popupUpdateRow data.Date.LocalDateTime
    tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
    popupDataRow "Blattnässe" (percentageToString data.LeafletMoisture)
    popupDataRow "Blatttemperatur" (temperatureToString data.LeafletTemperature)
]

let private soilPhDataToPopup (data: SoilPhSensorData) = [
    popupUpdateRow data.Date.LocalDateTime
    tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
    popupDataRow "PH-Wert" (sprintf "%.2f" data.SoilPh)
    popupDataRow "Bodentemperatur" (temperatureToString data.SoilTemperature)
]

let private averageWindSpeedDataToPopup (data: AverageWindSensorData) = [
    popupUpdateRow data.Date.LocalDateTime
    tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
    popupDataRow "Ø Windgeschwindigkeit" (windSpeedToString data.AverageWindSpeed)
]

let private createCurrentWindRow (currentWind: Wind) : ReactElement =
    let direction =
        match currentWind with
        | Wind wind ->
            sprintf
                "%s (%s)"
                (windSpeedToString wind.WindSpeed)
                (windDirectionToCompassDirection wind.WindDirection |> compassDirectionToLabel)
        | NoWind -> "-"

    popupDataRow "akt. Wind" direction

let private createAverageWindSpeedRow (maybeAverageWindSpeed: float option) =
    let text =
        match maybeAverageWindSpeed with
        | Some average -> windSpeedToString average
        | None -> windSpeedToString 0.0

    popupDataRow "durchschn. Windgeschw." text


let private weatherStationDataToPopup (data: WeatherStationSensorData) =
    let aggregatedValues =
        [
            createAggregatedMinMaxTemperaturePopup data.MinTemperature data.MaxTemperature
            createAggregatedMaxWindSpeedPopup data.MaxWindSpeed
        ]
        |> List.collect id
        |> aggregatedValuesDailyPopup

    [
        popupUpdateRow data.Date.LocalDateTime
        tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
        popupDataRow "Temperatur" (temperatureToString data.AirTemperature)
        popupDataRow "Rel. Luftfeuchte" (sprintf " %.2f %%" data.AirHumidity)
        createAverageWindSpeedRow data.AverageWindSpeed
        createCurrentWindRow data.CurrentWind
        tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
        aggregatedValues
    ]

let private liquidLevelDataToPopup (data: LiquidLevelData) = [
    popupUpdateRow data.Date.LocalDateTime
    tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
    popupDataRow "Füllstand" (liquidLevelToString data.LiquidLevel)
]

let private solarRadiationDataToPopup (data: SolarRadiationData) = [
    popupUpdateRow data.Date.LocalDateTime
    tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
    popupDataRow "Füllstand" (solarRadiationToString data.SolarRadiation)
]

let noSensorDataToolTip = [
    tr [] [
        td [ ColSpan 2 ] [ str "Heute noch keine Daten empfangen" ]
    ]
]

let private sensorDataToPopup (data: MapSensorData) =
    match data with
    | NoDataAvailable -> noSensorDataToolTip
    | NoPublicData -> noPublicSensorDataPopup
    | AirData airData -> airDataToPopup airData
    | GroundData groundData -> groundDataToPopup groundData
    | RainFallData rainFallData -> rainFallDataToPopup rainFallData
    | LeafletMoistureData sensorData -> leafletMoistureDataToPopup sensorData
    | SoilPhData sensorData -> soilPhDataToPopup sensorData
    | AverageWindSpeedData sensorData -> averageWindSpeedDataToPopup sensorData
    | WeatherStationData weatherStationSensorData -> weatherStationDataToPopup weatherStationSensorData
    | LiquidLevelData liquidLevelData -> liquidLevelDataToPopup liquidLevelData
    | SolarRadiationData data -> solarRadiationDataToPopup data

let makeMarkerPopup (sensor: MapSensor) dispatch =
    let rows = [
        makeMarkerPopupHeader sensor dispatch
        makeMarkerSymbolRow sensor.Data
    ]

    let rows = List.append rows (sensorDataToPopup sensor.Data)

    table [ Style [ Width "100%" ] ] [ tbody [] rows ]