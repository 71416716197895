module Client.Page.User.MyGateways

open Client
open Client.Infrastructure.Api
open Client.Components
open Client.Domain
open Client.InfrastructureTypes
open Client.DomainTypes.Msg
open Elmish
open Fable.React.Props
open Fulma
open Fable.React
open Shared.Dto.Dto
open Shared.Dto.Gateways
open Shared.Infrastructure
open Thoth.Elmish


type DataModel = {
    Gateways: MyGatewaysResponse
    Session: UserSession
}

type Model = Loadable<DataModel, UserSession>

let init (session: UserSession) =
    let requestData = {
        SessionKey = session.SessionKey
        Data = ()
    }

    let cmd =
        Cmd.OfAsync.perform api.getMyGateways requestData (MyGatewaysLoaded >> MyGateways)

    Loadable.Loading session, cmd

let update (msg: MyGatewaysMsg) (model: Model) =
    match (msg, model) with
    | MyGatewaysLoaded(Ok gateways), Loadable.Loading session ->
        Loadable.Data {
            Gateways = gateways
            Session = session
        },
        Cmd.none
    | MyGatewaysLoaded(AuthenticatedResponse.Error _), Loadable.Loading session ->
        let toastCmd =
            Toast.create "Beim Laden der Gateways ist ein Fehler aufgetreten" |> Toast.error

        model, toastCmd
    | _, _ -> model, Cmd.none

let private createGatewayStatusItem (text: string) (dot: StatusDot) =
    let imgSrc = StatusDot.toImageUrl dot

    Level.item [ Level.Item.CustomClass "sensor-status" ] [
        p [] [ str ("Status: " + text) ]
        img [ Src imgSrc ]
    ]

let private createGatewayConnectedStatusItem (isConnected: bool) =
    let status, statusDot =
        if isConnected then
            "Verbunden", StatusDot.Green
        else
            "Keine Verbindung", StatusDot.Red

    createGatewayStatusItem status statusDot

let private gatewayStatusRow (gateway: MyGatewayResponseEntry) =
    Level.level [] [
        match gateway.Status with
        | Some(Connected connected) ->
            Level.left [] [ createGatewayConnectedStatusItem true ]

            Level.right [] [
                Level.item [] [
                    sprintf "Verbunden seit: %s" (DateTime.toString connected.ConnectedAt.LocalDateTime)
                    |> str
                ]
            ]
        | Some(Disconnected disconnected) ->
            Level.left [] [ createGatewayConnectedStatusItem false ]

            Level.right [] [
                Level.item [] [
                    match disconnected.DisconnectedAt with
                    | Some disconnectedAt ->
                        sprintf "Keine Verbindung seit: %s" (DateTime.toString disconnectedAt.LocalDateTime)
                    | None -> "Schon seit längerem (noch nie?) gibt es keine Verbindung zum Gateway"
                    |> str
                ]
            ]
        | None ->
            Level.left [] [
                createGatewayStatusItem "Unbekannt" StatusDot.Grey
            ]

            Level.right [] [
                Level.item [] [ str "Fehler beim Laden des Gateway Status" ]
            ]
    ]

let private gatewayToBox (gateway: MyGatewayResponseEntry) =
    Box.box' [] [
        Heading.h1 [] [ str gateway.Name ]

        gatewayStatusRow gateway
    ]

let dataView (model: DataModel) =
    Container.container [ Container.isFullWidth ] (List.map gatewayToBox model.Gateways)

let view (model: Model) = Loadable.view dataView model