module Client.Page.Admin.AlertsList

open System
open Client
open Client.Domain
open Client.Infrastructure
open Client.DomainTypes
open Client.DomainTypes.Msg
open Elmish
open Fable.React.Props
open Fulma
open Microsoft.FSharp.Core
open Shared
open Shared.Dto
open Fable.React
open Shared.Dto.Dto
open Client.Infrastructure.Api
open Shared.Dto.User
open Shared.DtoTypes
open Shared.DtoTypes.PaginatedRequest
open Shared.Infrastructure
open Thoth.Elmish

type DataModel = {
    ContactInfos: AlertDto.ContactInfo list
    Alerts: AlertDto.RunningAlert list
    Users: UserDto list
    Sensors: IdValue<MapSensorDto> list
    LastAlertMessages: PersistedAlertMessage.Message list
}

let private dataModelCon contactInfos alerts users (sensors: IdValue<MapSensorDto> list) messages : DataModel =
    let sortedAlerts =
        alerts
        |> List.sortBy (fun (alert: AlertDto.RunningAlert) ->
            List.find (fun (sensor: IdValue<MapSensorDto>) -> sensor.Id = alert.Alert.SensorId) sensors
            |> fun sensor -> (MapSensor.getBaseData sensor.Value).Name
        )

    {
        ContactInfos = contactInfos
        Alerts = sortedAlerts
        Sensors = sensors
        Users = users
        LastAlertMessages = messages
    }

type LoadingModel = {
    ContactInfos: AlertDto.ContactInfo list option
    Alerts: AlertDto.RunningAlert list option
    Users: UserDto list option
    Sensors: IdValue<MapSensorDto> list option
    AlertMessages: PersistedAlertMessage.Message list option
}

type Model = Loadable<DataModel, LoadingModel>

let private createDataModel (loadingModel: LoadingModel) : Model option =
    dataModelCon
    |> Some
    |> Option.apply loadingModel.ContactInfos
    |> Option.apply loadingModel.Alerts
    |> Option.apply loadingModel.Users
    |> Option.apply loadingModel.Sensors
    |> Option.apply loadingModel.AlertMessages
    |> Option.map Loadable.Data

let update (msg: AlertListMsg) (model: Model) =
    match msg, model with
    | AlertListMsg.RunningAlertsReceived alertsResult, Loadable.Loading loading ->
        match alertsResult with
        | Ok alerts ->
            let newLoadingModel = { loading with Alerts = Some alerts }

            let model =
                createDataModel newLoadingModel
                |> Option.defaultValue (Loadable.Loading newLoadingModel)

            model, Cmd.none
        | Error _ ->
            let toastCmd =
                Toast.create "Fehler beim Laden der aktiven Bewarnungen" |> Toast.error

            model, toastCmd
    | AlertListMsg.ContactInfosReceived result, Loadable.Loading loadingModel ->
        match result with
        | Ok infos ->
            let newLoadingModel = { loadingModel with ContactInfos = Some infos }

            let model =
                createDataModel newLoadingModel
                |> Option.defaultValue (Loadable.Loading newLoadingModel)

            model, Cmd.none
        | Error error ->
            let toastCmd = Toast.create "Fehler beim Laden der Kontakdaten" |> Toast.error

            model, toastCmd
    | AlertListMsg.SensorsReceived response, Loadable.Loading loadingModel ->
        match response with
        | Ok sensors ->
            let newLoadingModel = { loadingModel with Sensors = Some sensors }

            let model =
                createDataModel newLoadingModel
                |> Option.defaultValue (Loadable.Loading newLoadingModel)

            model, Cmd.none
        | Error error ->
            let toastCmd = Toast.create "Fehler beim Laden der Sensoren" |> Toast.error

            model, toastCmd
    | AlertListMsg.UsersReceived response, Loadable.Loading loadingModel ->
        match response with
        | Ok users ->
            let newLoadingModel = { loadingModel with Users = Some users }

            let model =
                createDataModel newLoadingModel
                |> Option.defaultValue (Loadable.Loading newLoadingModel)

            model, Cmd.none
        | Error error ->
            let toastCmd = Toast.create "Fehler beim Laden der Benutzer" |> Toast.error

            model, toastCmd
    | AlertListMsg.AlertMessagesReceived response, Loadable.Loading loadingModel ->
        match response with
        | Ok messages ->
            let newLoadingModel = { loadingModel with AlertMessages = Some messages }

            let model =
                createDataModel newLoadingModel
                |> Option.defaultValue (Loadable.Loading newLoadingModel)

            model, Cmd.none
        | Error error ->
            let toastCmd =
                Toast.create "Fehler beim Laden der letzten Bewarnungen" |> Toast.error

            model, toastCmd
    | _ -> model, Cmd.none

let init (session: UserSession) =
    let requestData: AuthenticatedRequest<unit> = {
        SessionKey = session.SessionKey
        Data = ()
    }

    let messagesRequestData: AuthenticatedRequest<Pagination> = {
        SessionKey = session.SessionKey
        Data = {
            Offset = 0
            Limit = 50
        }
    }

    let cmds =
        Cmd.batch [
            Cmd.OfAsync.perform api.getAlerts requestData (AlertListMsg.RunningAlertsReceived >> Msg.AlertsList)
            Cmd.OfAsync.perform api.getContactInfos requestData (AlertListMsg.ContactInfosReceived >> Msg.AlertsList)
            Cmd.OfAsync.perform api.getAllMySensSensors requestData (AlertListMsg.SensorsReceived >> Msg.AlertsList)
            Cmd.OfAsync.perform api.getAllUsers requestData (AlertListMsg.UsersReceived >> AlertsList)
            Cmd.OfAsync.perform
                api.getAlertMessages
                messagesRequestData
                (AlertListMsg.AlertMessagesReceived >> AlertsList)
        ]

    let model =
        Loadable.Loading {
            ContactInfos = None
            Alerts = None
            Sensors = None
            AlertMessages = None
            Users = None
        }

    model, cmds

let temperatureToString = sprintf "%.2f °C"

let private createSensorLink dispatch (sensor: IdValue<MapSensorDto>) =
    a [
        OnClick(fun _ -> dispatch (Route.MySensSensor sensor.Id |> GoToRoute |> Global))
    ] [ str (MapSensor.getBaseData sensor.Value).Name ]

let private statusSinceCell (dateTime: DateTimeOffset) : ReactElement =
    let localDateTime = dateTime.LocalDateTime

    let timeElapsed = (DateTime.Now - localDateTime) |> TimeSpan.toHumanReadable

    let timeElapsedToolTip =
        sprintf "%s (%s)" (DateTime.toDayMonthString localDateTime) (DateTime.toTimeString localDateTime)

    td [] [ Tooltip.span timeElapsedToolTip timeElapsed ]

let private lastTemperatureCell (lastTemperature: DatedValue<float> option) : ReactElement =
    match lastTemperature with
    | Some lastTemperature ->
        let localDateTime = lastTemperature.Date.LocalDateTime

        let timeElapsed = (DateTime.Now - localDateTime) |> TimeSpan.toHumanReadable

        let timeElapsedToolTip =
            sprintf "%s (%s)" (DateTime.toDayMonthString localDateTime) (DateTime.toTimeString localDateTime)

        let content =
            sprintf "%s (vor %s)" (temperatureToString lastTemperature.Value) timeElapsed

        td [] [ Tooltip.span timeElapsedToolTip content ]
    | None -> td [] [ str "Warte auf neue Daten" ]

let alertToRow
    dispatch
    (sensors: IdValue<MapSensorDto> list)
    (contactInfos: AlertDto.ContactInfo list)
    (index: int)
    (alert: AlertDto.RunningAlert)
    =
    let sensorName =
        sensors
        |> List.tryFind (fun sensor -> sensor.Id = alert.Alert.SensorId)
        |> Option.map (createSensorLink dispatch)
        |> Option.defaultValue (str "Unbekannter Sensor?")

    let stateClass =
        match alert.State with
        | AlertDto.Ready _ -> "alert_ready"
        | AlertDto.Alerted _ -> "alert_alerted"
        | AlertDto.Outdated _ -> "alert_outdated"

    let contactInfo =
        contactInfos
        |> List.tryFind (fun contactInfo -> contactInfo.Id = alert.Alert.ContactInfoId)
        |> Option.map (fun contactInfo ->
            sprintf "%s (%s)" contactInfo.Name (AlertDto.contactInfoToString contactInfo.Data)
        )
        |> Option.defaultValue "Unbekannte Kontakdaten?"

    tr [] [
        td [] [ Table.rowIndexString index ]
        td [] [ sensorName ]
        td [] [ str contactInfo ]
        td [] [
            str (AlertDto.thresholdTypeToString alert.Alert.TemperatureType)
        ]
        td [] [
            str (AlertDto.phoneAlertTypeToString alert.Alert.PhoneAlertType)
        ]
        td [] [ str (sprintf "%.1f ˚C" alert.Alert.Threshold) ]
        lastTemperatureCell alert.LastTemperature
        td [ Class stateClass ] [ str (AlertDto.stateToString alert.State) ]
        td [] [
            str (
                AlertDto.stateToCount alert.State
                |> Option.map (sprintf "%d")
                |> Option.defaultValue "-"
            )
        ]
        alert.State |> AlertDto.stateToSince |> statusSinceCell
    ]

let private alertMessageToRow dispatch (users: UserDto list) (message: PersistedAlertMessage.Message) =
    let user =
        users
        |> List.tryFind (fun user -> user.Id = message.UserId)
        |> Option.map getFullName
        |> Option.defaultValue "Unbekannter Benutzer"

    tr [] [
        td [] [
            DateTime.toShortString message.CreatedAt |> str
        ]
        td [] [ str user ]
        td [] [
            AlertMessage.methodToLabel message.Method |> str
        ]
        td [] [ str message.PhoneNumber ]
        td [] [ AlertMessage.typeToLabel message.Type |> str ]
        td [] [ str message.Content ]
        td [] [ AlertMessage.stateToLabel message.State |> str ]
        td [] [
            DateTime.toShortString message.Timestamp |> str
        ]
    ]

let private alertMessagesTable dispatch (model: DataModel) =
    let headline =
        tr [] [
            th [] [ str "Erstellt am" ]
            th [] [ str "Benutzer" ]
            th [] [ str "Methode" ]
            th [] [ str "Telefonnummer" ]
            th [] [ str "Typ" ]
            th [] [ str "Inhalt" ]
            th [] [ str "Status" ]
            th [] [ str "Status-Zeit" ]
        ]

    if List.isEmpty model.LastAlertMessages then
        p [] [ str "Keine Bewarnungs-Meldungen gefunden" ]
    else
        let rows = List.map (alertMessageToRow dispatch model.Users) model.LastAlertMessages

        Table.table [
            Table.IsBordered
            Table.IsFullWidth
            Table.IsStriped
        ] [
            thead [] [ headline ]

            tbody [] rows
        ]

let alertsTable dispatch (model: DataModel) =
    let headline =
        tr [] [
            th [] [ str "#" ]
            th [] [ str "Sensor" ]
            th [] [ str "Kontakt-Infos" ]
            th [] [ str "Verwendete Temperatur" ]
            th [] [ str "Bewarnungsart" ]
            th [] [ str "Temperatur-Grenze" ]
            th [] [ str "Aktuelle Temperatur" ]
            th [] [ str "Status der Alarmierung" ]
            th [] [ str "Anzahl Sensordaten über/unter Grenze" ]
            th [] [ str "Aktueller Status seit" ]
        ]

    if List.isEmpty model.Alerts then
        p [] [ str "Keine aktiven Bewarnungen gefunden" ]
    else
        let rows =
            List.mapi (alertToRow dispatch model.Sensors model.ContactInfos) model.Alerts

        Table.table [
            Table.IsBordered
            Table.IsFullWidth
            Table.IsStriped
        ] [
            thead [] [ headline ]

            tbody [] rows
        ]

let private dataView dispatch (data: DataModel) =
    Container.container [ Container.IsFluid ] [
        Heading.h1 [] [ str "Aktive Bewarnungen" ]
        Table.scrollableTable (alertsTable dispatch data)
        Heading.h1 [] [ str "Letzte Benachrichtigungen" ]
        Table.scrollableTable (alertMessagesTable dispatch data)
    ]

let view (model: Model) dispatch = Loadable.view (dataView dispatch) model