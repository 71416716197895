module Client.Page.Home

open Client.Infrastructure.Api
open Client.Domain
open Client.Infrastructure
open Client.DomainTypes
open Client.DomainTypes.Msg
open Elmish
open Fable.FontAwesome
open Fable.React
open Fable.React.Props
open Fulma
open Shared.Dto.Dashboard
open Shared.Dto.Dto
open Shared.Dto.User
open Shared

let stringNoneIfEmpty str =
    if System.String.IsNullOrEmpty str then None else Some str

type Model = {
    Devices: Loadable<DashboardResponse, unit>
    Session: UserSession
}

let update msg model =
    match msg with
    | HomeMsg.DashboardResponseReceived response ->
        match response with
        | Ok data -> { model with Devices = Loadable.Data data }, Cmd.none
        | AuthenticatedResponse.Error _ ->
            { model with Devices = Loadable.Error "Fehler beim Laden des Geräte Status" }, Cmd.none


let init (session: UserSession) =
    let request = {
        SessionKey = session.SessionKey
        Data = ()
    }

    {
        Devices = Loadable.Loading()
        Session = session
    },
    Cmd.OfAsync.perform api.getDashboard request (HomeMsg.DashboardResponseReceived >> Home)

let private sensorsDeviceRow (data: DevicesOnlineSummary) =
    let text, imgSrc =
        match data with
        | AllOnline count ->
            let text = sprintf "Alle %d aktiven Sensoren sind online" count

            text, Some "/images/green-mark.png"
        | NoDevicesFound -> "Keine aktiven Sensoren im System gefunden", None
        | NotAllOnline amount ->
            let text =
                sprintf "%d von %d aktiven Sensore(n) sind online" amount.Online amount.TotalCount

            text, Some "/images/warning-sign.png"

    [
        Icon.icon [] [ Fa.i [ Fa.Solid.MapMarker ] [] ]
        span [ classList [ "text", true ] ] [ str text ]

        match imgSrc with
        | Some src ->
            img [
                Src src
                Style [ MarginLeft "0.5rem"; Height "1.5rem" ]
            ]
        | None -> ()
    ]

let private gatewayDeviceRow (data: DevicesOnlineSummary) =
    let text, imgSrc =
        match data with
        | AllOnline count ->
            let text = sprintf "Alle %d aktiven Empfänger sind online" count

            text, Some "/images/green-mark.png"
        | NoDevicesFound -> "Keine aktiven Empfänger im System gefunden", None
        | NotAllOnline amount ->
            let text =
                sprintf "%d von %d aktiven Empfänger sind online" amount.Online amount.TotalCount

            text, Some "/images/warning-sign.png"

    [
        Icon.icon [] [ Fa.i [ Fa.Solid.BroadcastTower ] [] ]
        span [ classList [ "text", true ] ] [ str text ]

        match imgSrc with
        | Some src ->
            img [
                Src src
                Style [ MarginLeft "0.5rem"; Height "1.5rem" ]
            ]
        | None -> ()
    ]

let private devicesView (data: DashboardResponse) =
    div [ classList [ "dashboard", true ] ] [
        p
            [
                Style [
                    Display DisplayOptions.Flex
                    AlignItems AlignItemsOptions.Center
                ]
            ]
            (sensorsDeviceRow data.Sensors)

        p
            [
                Style [
                    Display DisplayOptions.Flex
                    AlignItems AlignItemsOptions.Center
                ]
            ]
            (gatewayDeviceRow data.Gateways)
    ]

let view (model: Model) dispatch =
    Hero.hero [ Hero.IsFullheightWithNavbar ] [
        Hero.head [] []
        Hero.body [
            GenericOption.CustomClass "centered"
            GenericOption.Props [ Style [ FlexDirection "column" ] ]
        ] [
            Heading.h1 [ Heading.CustomClass "has-text-centered" ] [ str "Willkommen bei MySens" ]

            Heading.h3 [ Heading.Is6; Heading.IsSubtitle ] [
                str ("Eingeloggt als " + (getFullName model.Session.User))
            ]

            (*Bulma.message [
                Bulma.color.isWarning
                prop.children [
                    Bulma.messageHeader [ prop.text "Server Updates" ]
                    Bulma.messageBody [
                        prop.text
                            "Am 25. und 26. November 2024 werden die Server gewartet und auf den neuesten Stand gebracht.
                        Aufgrund dessen kann es in diesen zwei Tagen vorkommen, dass die Webseite nicht immer erreichbar ist."
                    ]
                ]
            ]*)

            p [ Style [ MarginTop "3rem" ] ] [
                Button.button [
                    Button.Color Color.IsLink
                    Clickable.onClickGoToRoute dispatch (Route.SensorMap None) |> Button.OnClick
                    Button.IsOutlined
                ] [
                    Icon.icon [ Icon.Size IsSmall ] [ Fa.i [ Fa.Solid.MapMarkedAlt ] [] ]
                    span [] [ str "Zur Karte" ]
                ]
            ]
            p [ Style [ Margin "1.5rem" ] ] [
                Button.button [
                    Button.Color Color.IsLink
                    Clickable.onClickGoToRoute dispatch Route.MySensorsData |> Button.OnClick
                    Button.IsOutlined
                ] [
                    Icon.icon [ Icon.Size IsSmall ] [ Fa.i [ Fa.Solid.ChartLine ] [] ]
                    span [] [ str "Zu den aktuellen Messwerten" ]
                ]
            ]

            Heading.h3 [
                Heading.Props [ Style [ Margin "2rem" ] ]
                Heading.IsSpaced
            ] [ str "Meine Geräte" ]

            Loadable.view devicesView model.Devices
        ]
    ]