namespace Client.Infrastructure

open Browser.Types
open Client.DomainTypes
open Client.DomainTypes.Msg

module Clickable =
    let onClickPreventDefault (f: MouseEvent -> unit) (event: MouseEvent) =
        event.preventDefault ()

        f event

    let goToRouteMsg (route: Route) = GoToRoute route |> Global

    let onClickGoToRoute (dispatch: Msg -> unit) (route: Route) (event: MouseEvent) =
        let f _ = route |> goToRouteMsg |> dispatch

        onClickPreventDefault f event