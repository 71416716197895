module Client.Map.Symbol.RadiationIntensity

open Shared.Dto.MapSensorData

type RadiationIntensity =
    | VeryHigh
    | High
    | Medium
    | Low

let toImageSrc (intensity: RadiationIntensity) =
    let intensity =
        match intensity with
        | VeryHigh -> "3"
        | High -> "2"
        | Medium -> "1"
        | Low -> "0"

    sprintf "/images/radiation-intensity/SR%s.svg" intensity

let private solarRadiationToIntensity (solarRadiation: int) : RadiationIntensity =
    if solarRadiation > 1000 then VeryHigh
    else if solarRadiation > 400 then High
    else if solarRadiation > 100 then Medium
    else Low

let getRadiationIntensity (sensorData: MapSensorData) =
    match sensorData with
    | SolarRadiationData data -> data.SolarRadiation |> solarRadiationToIntensity |> Some
    | _ -> None