module Shared.Dto.MapSensorData

open System
open Shared
open Shared.Dto.Dto
open Shared.DtoTypes.LeafletWetness
open Shared.DtoTypes.MapSensorData

type AirSensorData = {
    Date: DateTimeOffset
    AirTemperature: float
    AirHumidity: float
    HumidTemperature: float
    DewPoint: float
    AbsoluteHumidity: float
    MinAirTemperature: DatedValue<float>
    MaxAirTemperature: DatedValue<float>
    Rssi: int option
    BatteryLevel: BatteryLevel
    LeafletWetness: LeafletWetness
}

type SoilMoistureSection =
    | F1
    | F2
    | F3
    | F4
    | F5
    | F6
    | F7
    | F8
    | F9

type GroundSensorData = {
    Date: DateTimeOffset
    GroundTemperature: float
    GroundHumidity: float
    SoilConductivity: float
    MinGroundHumidity: DatedValue<float>
    MaxGroundHumidity: DatedValue<float>
    Rssi: int option
    BatteryLevel: BatteryLevel
    MoistureSection: SoilMoistureSection option
}

type RainFallSensorData = {
    Date: DateTimeOffset
    CurrentRainFall: float
    RainFallLastHour: float option
    RainFallLast24h: float option
    Rssi: int option
    BatteryLevel: BatteryLevel
    TotalRainFallAmount: float
}

type LeafletMoistureSensorData = {
    Date: DateTimeOffset
    Rssi: int option
    BatteryLevel: BatteryLevel

    LeafletMoisture: float
    LeafletTemperature: float
}

type SoilPhSensorData = {
    Date: DateTimeOffset
    Rssi: int option
    BatteryLevel: BatteryLevel

    SoilPh: float
    SoilTemperature: float
}

type AverageWindSensorData = {
    Date: DateTimeOffset
    Rssi: int option
    BatteryLevel: BatteryLevel

    AverageWindSpeed: float
}

type LiquidLevelData = {
    Date: DateTimeOffset
    Rssi: int option
    BatteryLevel: BatteryLevel

    LiquidLevel: int
}

type SolarRadiationData = {
    Date: DateTimeOffset
    Rssi: int option
    BatteryLevel: BatteryLevel

    SolarRadiation: int
}

let windToWindSpeed (wind: Wind) =
    match wind with
    | NoWind -> None
    | Wind data -> Some data.WindSpeed

type MapSensorDataList =
    | NoDataAvailable
    | AirData of AirSensorData list
    | GroundData of GroundSensorData list
    | RainFallData of RainFallSensorData list
    | LeafletMoistureData of LeafletMoistureSensorData list
    | SoilPhData of SoilPhSensorData list
    | AverageWindSpeed of AverageWindSensorData list
    | WeatherStation of WeatherStationSensorData list
    | LiquidLevel of LiquidLevelData list
    | SolarRadiation of SolarRadiationData list

type MapSensorData =
    | NoDataAvailable
    | NoPublicData
    | AirData of AirSensorData
    | GroundData of GroundSensorData
    | RainFallData of RainFallSensorData
    | LeafletMoistureData of LeafletMoistureSensorData
    | SoilPhData of SoilPhSensorData
    | AverageWindSpeedData of AverageWindSensorData
    | WeatherStationData of WeatherStationSensorData
    | LiquidLevelData of LiquidLevelData
    | SolarRadiationData of SolarRadiationData

type MapSensor = {
    Id: int
    Name: string
    SecondName: string option
    Latitude: double
    Longitude: double
    Data: MapSensorData
    DetailsAllowed: bool
}

type ActiveMapSensorDataDto = {
    Id: int
    Name: string
    SecondName: string option
    Location: Location
    Data: MapSensorData
}

type DefectiveMyMapSensorDataDto = {
    Id: int
    Name: string
    SecondName: string option
}

type DisabledMyMapSensorDataDto = {
    Name: string
    SecondName: string option
}

type MyMapSensorDataDto =
    | Active of ActiveMapSensorDataDto
    | Defective of DefectiveMyMapSensorDataDto

type UserGroupMapSensorsDto = {
    Name: string
    Senors: ActiveMapSensorDataDto list
}

type MapSensorDataResponse = {
    MySensors: MyMapSensorDataDto list
    Groups: UserGroupMapSensorsDto list
}

let getMyMapSensorName (sensor: MyMapSensorDataDto) : string =
    match sensor with
    | Active active -> active.Name
    | Defective defective -> defective.Name

let getRssiOfSensorData (data: MapSensorData) =
    match data with
    | AirData airData -> airData.Rssi
    | GroundData groundData -> groundData.Rssi
    | RainFallData data -> data.Rssi
    | LeafletMoistureData data -> data.Rssi
    | SoilPhData data -> data.Rssi
    | AverageWindSpeedData data -> data.Rssi
    | _ -> None

let getBatteryLevel (data: MapSensorData) =
    match data with
    | AirData airData -> Some airData.BatteryLevel
    | GroundData groundData -> Some groundData.BatteryLevel
    | RainFallData data -> Some data.BatteryLevel
    | LeafletMoistureData data -> Some data.BatteryLevel
    | SoilPhData data -> Some data.BatteryLevel
    | AverageWindSpeedData data -> Some data.BatteryLevel
    | _ -> None

let getSensorDataDate (data: MapSensorData) =
    match data with
    | NoDataAvailable
    | NoPublicData -> None
    | AirData data -> Some data.Date
    | GroundData data -> Some data.Date
    | RainFallData data -> Some data.Date
    | LeafletMoistureData data -> Some data.Date
    | SoilPhData data -> Some data.Date
    | AverageWindSpeedData data -> Some data.Date
    | WeatherStationData data -> Some data.Date
    | LiquidLevelData data -> Some data.Date
    | SolarRadiationData data -> Some data.Date

let windDirectionToCompassDirection (degree: float) : CompassDirection =
    if degree >= 337.5 || degree <= 22.5 then
        N
    else if degree > 22.5 && degree < 67.5 then
        NE
    else if degree >= 67.5 && degree <= 112.5 then
        E
    else if degree > 112.5 && degree < 157.5 then
        SE
    else if degree >= 157.5 && degree <= 202.5 then
        S
    else if degree > 202.5 && degree < 247.5 then
        SW
    else if degree >= 247.5 && degree <= 292.5 then
        W
    else if degree > 292.5 && degree < 337.5 then
        NW
    else
        failwithf $"%f{degree} Degrees are not a valid WindDirection"

let compassDirectionToLabel (direction: CompassDirection) : string =
    match direction with
    | N -> "N"
    | NE -> "NO"
    | E -> "O"
    | SE -> "SO"
    | S -> "S"
    | SW -> "SW"
    | W -> "W"
    | NW -> "NW"

let toAirData (data: MapSensorData) : AirSensorData option =
    match data with
    | AirData air -> Some air
    | _ -> None

let toSoilData (data: MapSensorData) : GroundSensorData option =
    match data with
    | GroundData soil -> Some soil
    | _ -> None

let toSoilPhData (data: MapSensorData) : SoilPhSensorData option =
    match data with
    | SoilPhData ph -> Some ph
    | _ -> None

let toRainFallData (data: MapSensorData) : RainFallSensorData option =
    match data with
    | RainFallData rainFall -> Some rainFall
    | _ -> None

let toLeafletMoistureData (data: MapSensorData) : LeafletMoistureSensorData option =
    match data with
    | LeafletMoistureData leafletMoisture -> Some leafletMoisture
    | _ -> None

let toAverageWindSpeedData (data: MapSensorData) : AverageWindSensorData option =
    match data with
    | AverageWindSpeedData averageWindSpeed -> Some averageWindSpeed
    | _ -> None

let toWeatherStationData (data: MapSensorData) : WeatherStationSensorData option =
    match data with
    | WeatherStationData weatherStation -> Some weatherStation
    | _ -> None

let toLiquidLevelData (data: MapSensorData) : LiquidLevelData option =
    match data with
    | LiquidLevelData liquidData -> Some liquidData
    | _ -> None

let toSolarRadiationData (data: MapSensorData) : SolarRadiationData option =
    match data with
    | SolarRadiationData data -> Some data
    | _ -> None