module Client.Map.MapMarker

open System
open Fable.Core
open ReactLeaflet
open Leaflet
open Shared.Dto.MapSensorData
open Fable.Core.JsInterop


type IconFactory =
    abstract createIcon: props: obj -> Icon<obj>

[<ImportAll("../icon.js")>]
let iconFactory: IconFactory = jsNative

type IconProps =
    | IconUrl of string
    | IconSize of obj array
    | IconAnchor of obj array
    | PopupAnchor of obj array
    | TooltipAnchor of obj array

let makePosition latitude longitude =
    LatLngExpression.Case3(latitude, longitude)

let createIcon (fileName: string) =
    let iconOptions =
        keyValueList CaseRules.LowerFirst [
            IconUrl(sprintf "flags/%s" fileName)
            IconSize [| box 25; box 41 |]
            IconAnchor [| box 12; box 41 |]
            PopupAnchor [| box 0; box -42 |]
        ]

    iconFactory.createIcon iconOptions |> U2.Case1

let getTotalMinutesLimit (data: MapSensorData) =
    match data with
    | NoDataAvailable
    | NoPublicData -> None
    | WeatherStationData _ -> Some 15.0
    | AirData _
    | RainFallData _
    | AverageWindSpeedData _
    | LiquidLevelData _ -> Some 30.0
    | GroundData _
    | LeafletMoistureData _
    | SoilPhData _
    | SolarRadiationData _ -> Some 50.0

let isOldAgeData (data: MapSensorData) =
    let totalMinutesLimit = (getTotalMinutesLimit data)

    getSensorDataDate data
    |> Option.map (fun date -> (DateTimeOffset.UtcNow - date).TotalMinutes)
    |> Option.map2 (fun limit totalMinutes -> totalMinutes > limit) totalMinutesLimit
    |> Option.defaultValue true


let getIconNameByMapSensorData (data: MapSensorData) =
    let isOldAge = isOldAgeData data

    match (data, isOldAge) with
    | NoDataAvailable, _
    | NoPublicData, _ -> "Grey.png"
    | AirData _, true -> "TL_grey.svg"
    | AirData _, false -> "TL.svg"
    | GroundData _, true -> "BT_grey.svg"
    | GroundData _, false -> "BT_brown.svg"
    | RainFallData _, true -> "RF_grey.svg"
    | RainFallData _, false -> "RF_blue.svg"
    | LeafletMoistureData _, true -> "BN_grey.svg"
    | LeafletMoistureData _, false -> "BN_blue.svg"
    | SoilPhData _, true -> "PH_grey.svg"
    | SoilPhData _, false -> "PH_gold.svg"
    | AverageWindSpeedData _, true -> "WI_grey.svg"
    | AverageWindSpeedData _, false -> "WI_violett.svg"
    | WeatherStationData _, true -> "WS_grey.svg"
    | WeatherStationData _, false -> "WS_orange.svg"
    | LiquidLevelData _, true -> "FS_grey.png"
    | LiquidLevelData _, false -> "FS_black.png"
    | SolarRadiationData _, true -> "SR_grey.svg"
    | SolarRadiationData _, false -> "SR_yellow.svg"


let makeMarker data latLong popupContent =
    let icon = getIconNameByMapSensorData data |> createIcon

    marker [
        MarkerProps.Position latLong
        MarkerProps.Icon icon
    ] [
        popup [
            PopupProps.KeepInView false
            PopupProps.MaxWidth 800.
            PopupProps.MinWidth 250.
        ] [ popupContent ]
    ]