module Client.Components.Graph.SolarRadiationSensor

open Client.Components.Graph.Recharts
open Client.Components.GraphCommon
open Client.Domain.MeasuredValueString
open Feliz.Recharts
open Fulma
open Shared.Dto.MapSensorData
open Shared.Dto.SensorGraphData
open Shared.Infrastructure

type SolarRadiationGraphNode = {
    Ticks: double
    SolarRadiation: float
}

let toSolarRadiationSensorGraphData (data: GraphSolarRadiationDataDto) : SolarRadiationGraphNode = {
    Ticks = data.TimeStamp.ToUnixTimeSeconds() |> double
    SolarRadiation = data.SolarRadiation
}

let mapDataToSolarRadiation (data: GraphDataDto) =
    match data with
    | SolarRadiation data -> Some data
    | _ -> None

let currentSolarRadiationDataBox (data: SolarRadiationData) = [
    Level.level [] [
        currentDataLevelItem "Wann?" (DateTime.toString data.Date.LocalDateTime)
        currentDataLevelItem "Globalstrahlung" (solarRadiationToString data.SolarRadiation)
    ]
]

let private createSolarRadiationYAxisTicks (data: SolarRadiationGraphNode list) =
    let values = data |> List.map (fun item -> item.SolarRadiation)

    let min = List.min values
    let max = List.max values

    AxisTicks.generate true 5. min max

let solarRadiationGraphs (graphData: SimpleGraphData<SolarRadiationGraphNode>) =
    let xDomain = xAxis.domain (domain.min, domain.max)
    let yDomain = yAxis.domain (domain.min, domain.max)
    let xAxisTickInterval = xAxis.interval.preserveStartEnd

    let solarRadiationYAxisTicks =
        graphData.Data |> createSolarRadiationYAxisTicks |> AxisTicks.createYProperty

    let dataKey = (fun (p: SolarRadiationGraphNode) -> p.Ticks) |> xAxis.dataKey

    let solarRadiationChart =
        Recharts.lineChart [
            lineChart.syncId "syncId1"
            lineChart.data graphData.Data
            lineChart.children [
                Recharts.xAxis [
                    dataKey
                    xAxis.number
                    xAxis.scale.time
                    xAxis.tickFormatter formatTimeTick
                    xDomain
                    xAxisTickInterval
                ]
                Recharts.yAxis [
                    yAxis.number
                    solarRadiationYAxisTicks
                    yAxis.width 80
                    yDomain
                    yAxis.unit "W/m²"
                ]
                Recharts.legend []
                Recharts.tooltip [ tooltip.content tooltipContent ]
                Recharts.line [
                    line.dot false
                    line.dataKey (fun p -> p.SolarRadiation)
                    line.name "Globalstrahlung [W/m²]"
                    line.stroke "#cc9933"
                ]

                Recharts.cartesianGrid [ cartesianGrid.strokeDasharray [| 3; 3 |] ]
            ]
        ]
        |> fullHeightGraphBox "Verlauf der Globalstrahlung"


    [ solarRadiationChart ]

let graphDataToSolarRadiationGraphData data =
    data
    |> List.map toSolarRadiationSensorGraphData
    |> (fun list -> { Data = list })