module Client.Page.Login

open Client.Infrastructure.Api
open Client
open Client.Components
open Client.DomainTypes.Msg
open Client.DomainTypes
open Elmish
open Fable.FontAwesome
open Fulma
open Shared
open Fable.React
open Shared.Dto.Dto
open Shared.Infrastructure
open Thoth.Elmish

type LoginModel = {
    Mail: string option
    Password: string option
    RequestRunning: bool
    Target: Route
}

type Model =
    | Login of LoginModel
    | Loading

let init (route: Route) (session: Session) : Model * Cmd<Msg> =
    let model = {
        Mail = None
        Password = None
        RequestRunning = false
        Target = route
    }

    match session with
    | Anonymous -> Login model, Cmd.none
    | UserSession _ -> Login model, Cmd.ofMsg (GoToRoute route |> Global)


let update (msg: LoginMsg) (model: Model) : Model * Cmd<Msg.Msg> =
    match (msg, model) with
    | LoginMsg.MailUpdated mail, Login loginModel -> Login { loginModel with Mail = mail }, Cmd.none
    | LoginMsg.PasswordChanged password, Login loginModel -> Login { loginModel with Password = password }, Cmd.none
    | LoginMsg.LoginButtonPressed data, Login loginModel ->
        let cmd = Cmd.OfAsync.perform api.login data LoginResult

        Login { loginModel with RequestRunning = true }, Cmd.map Msg.Login cmd
    | LoginMsg.LoginResult response, Login loginModel ->
        match response with
        | Unsuccessful message ->
            let toastCmd = Toast.create message |> Toast.error

            Login { loginModel with RequestRunning = false }, toastCmd
        | Successful userSession ->
            Login { loginModel with RequestRunning = false }, Cmd.ofMsg (GoToRoute loginModel.Target |> Global)
    | _, _ -> model, Cmd.none

let buttonOptions (model: LoginModel) dispatch =
    let maybeRequestData =
        Option.map2
            (fun mail password -> {
                Mail = mail
                Password = password
            })
            model.Mail
            model.Password

    let buttonOptions = [
        Button.Color IsLink
        Button.IsLoading model.RequestRunning
        Button.Disabled(Option.isNone maybeRequestData)
    ]

    maybeRequestData
    |> Option.map (fun requestData ->
        SubmitButton.onClick (fun _ -> dispatch (LoginButtonPressed requestData |> Msg.Login))
    )
    |> List.addToListIfSome buttonOptions

let containerBox (model: LoginModel) (dispatch: Msg.Msg -> unit) =
    Box.box' [] [
        form [] [
            Field.div [] [
                Label.label [] [ str "E-Mail Adresse" ]
                Control.div [ Control.HasIconLeft ] [
                    Input.email [
                        Input.Id "mail"
                        Input.Value(String.fromOption model.Mail)
                        Input.Placeholder "z. B. max.mustermann@example.com"
                        Input.OnChange(fun x -> LoginMsg.MailUpdated(String.toOption x.Value) |> Msg.Login |> dispatch)
                    ]

                    Icon.icon [ Icon.Size IsSmall; Icon.IsLeft ] [ Fa.i [ Fa.Solid.Envelope ] [] ]
                ]
            ]
            Field.div [] [
                Label.label [] [ str "Passwort" ]
                Control.div [ Control.HasIconLeft ] [
                    Input.password [
                        Input.Id "password"
                        Input.Value(String.fromOption model.Password)
                        Input.Placeholder "z. B. 1234"
                        Input.OnChange(fun x ->
                            LoginMsg.PasswordChanged(String.toOption x.Value) |> Msg.Login |> dispatch
                        )
                    ]

                    Icon.icon [ Icon.Size IsSmall; Icon.IsLeft ] [ Fa.i [ Fa.Solid.Key ] [] ]
                ]
            ]
            Level.level [] [
                Level.left [] [
                    Level.item [] [
                        Control.p [] [
                            Button.a [
                                Button.Color IsLight
                                Button.OnClick(fun _ -> dispatch (GoToRoute Route.PasswordLost |> Global))
                            ] [ str "Passwort vergessen?" ]
                        ]
                    ]
                ]
                Level.right [] [
                    Level.item [] [
                        Control.p [] [
                            Button.button (buttonOptions model dispatch) [
                                Icon.icon [] [ Fa.i [ Fa.Solid.SignInAlt ] [] ]
                                span [] [ str "Einloggen" ]
                            ]
                        ]
                    ]
                ]
            ]
        ]
    ]

let loginView (model: LoginModel) dispatch =
    Hero.body [] [
        Container.container [] [
            Column.column [
                Column.Width(Screen.All, Column.Is6)
                Column.Offset(Screen.All, Column.Is3)
            ] [
                Heading.p [
                    Heading.Modifiers [
                        Modifier.TextAlignment(Screen.All, TextAlignment.Centered)
                    ]
                ] [ str "MySens - Login" ]
                containerBox model dispatch
            ]
        ]
    ]

let loadingView: ReactElement =
    Hero.body [] [
        Container.container [] [
            Column.column [
                Column.Width(Screen.All, Column.Is6)
                Column.Offset(Screen.All, Column.Is3)
            ] [
                Heading.p [
                    Heading.Modifiers [
                        Modifier.TextAlignment(Screen.All, TextAlignment.Centered)
                    ]
                ] [ str "Wird geladen" ]
            ]
        ]
    ]

let view (model: Model) dispatch =
    match model with
    | Login loginModel -> loginView loginModel dispatch
    | Loading -> loadingView