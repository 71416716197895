module Client.Components.Graph.LiquidLevelSensor

open Client.Components.Graph.Recharts
open Client.Components.GraphCommon
open Client.Domain.MeasuredValueString
open Feliz.Recharts
open Fulma
open Shared.Dto.MapSensorData
open Shared.Dto.SensorGraphData
open Shared.Infrastructure

type LiquidLevelGraphNode = {
    Ticks: double
    LiquidLevel: float
}

let toLiquidLevelSensorGraphData (data: GraphLiquidLevelDataDto) : LiquidLevelGraphNode = {
    Ticks = data.TimeStamp.ToUnixTimeSeconds() |> double
    LiquidLevel = data.LiquidLevel
}

let mapDataToLiquidLevel (data: GraphDataDto) =
    match data with
    | LiquidLevel data -> Some data
    | _ -> None

let currentLiquidLevelDataBox (data: LiquidLevelData) = [
    Level.level [] [
        currentDataLevelItem "Wann?" (DateTime.toString data.Date.LocalDateTime)
        currentDataLevelItem "Füllstand" (liquidLevelToString data.LiquidLevel)
    ]
]

let private createLiquidLevelYAxisTicks (data: LiquidLevelGraphNode list) =
    let values = data |> List.map (fun item -> item.LiquidLevel)

    let min = List.min values
    let max = List.max values

    AxisTicks.generate true 5. min max

let liquidLevelGraphs (graphData: SimpleGraphData<LiquidLevelGraphNode>) =
    let xDomain = xAxis.domain (domain.min, domain.max)
    let yDomain = yAxis.domain (domain.min, domain.max)
    let xAxisTickInterval = xAxis.interval.preserveStartEnd

    let liquidLevelYAxisTicks =
        graphData.Data |> createLiquidLevelYAxisTicks |> AxisTicks.createYProperty

    let dataKey = (fun (p: LiquidLevelGraphNode) -> p.Ticks) |> xAxis.dataKey

    let liquidLevelChart =
        Recharts.lineChart [
            lineChart.syncId "syncId1"
            lineChart.data graphData.Data
            lineChart.children [
                Recharts.xAxis [
                    dataKey
                    xAxis.number
                    xAxis.scale.time
                    xAxis.tickFormatter formatTimeTick
                    xDomain
                    xAxisTickInterval
                ]
                Recharts.yAxis [
                    yAxis.number
                    liquidLevelYAxisTicks
                    yDomain
                    yAxis.unit "mm"
                ]
                Recharts.legend []
                Recharts.tooltip [ tooltip.content tooltipContent ]
                Recharts.line [
                    line.dot false
                    line.dataKey (fun p -> p.LiquidLevel)
                    line.name "Füllstand [mm]"
                    line.stroke "#cc9933"
                ]

                Recharts.cartesianGrid [ cartesianGrid.strokeDasharray [| 3; 3 |] ]
            ]
        ]
        |> fullHeightGraphBox "Verlauf des Füllstandes"


    [ liquidLevelChart ]

let graphDataToLiquidLevelGraphData data =
    data |> List.map toLiquidLevelSensorGraphData |> (fun list -> { Data = list })