module Client.Map.Symbol.AverageWindSpeed

open Shared.Dto.MapSensorData

let mapSensorDataToAverageWindSpeed (data: MapSensorData) =
    match data with
    | AverageWindSpeedData data -> Some data.AverageWindSpeed
    | WeatherStationData data -> Some(Option.defaultValue 0. data.AverageWindSpeed)
    | _ -> None

let getIconForWindSpeed (windSpeed: float) : string =
    let category =
        if windSpeed <= 1.8 then "0"
        else if windSpeed <= 3.6 then "1"
        else if windSpeed <= 9.0 then "2"
        else "3"

    sprintf "/images/wind-speed/wind_%s.svg" category