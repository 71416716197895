namespace Client.Map.Symbol

open Shared.Dto.MapSensorData


[<RequireQualifiedAccess>]
module SignalStrength =
    type T =
        | Unknown
        | VeryBad
        | Bad
        | Ok
        | Good
        | VeryGood


    let fromRssi rssi =
        if rssi > -100 then VeryGood
        else if rssi > -110 then Good
        else if rssi > -120 then Ok
        else if rssi > -124 then Bad
        else VeryBad

    let fromMapSensorData (data: MapSensorData) =
        match data with
        | NoDataAvailable
        | NoPublicData -> None
        | AirData airData -> Option.map fromRssi airData.Rssi
        | GroundData groundData -> Option.map fromRssi groundData.Rssi
        | RainFallData data -> Option.map fromRssi data.Rssi
        | LeafletMoistureData data -> Option.map fromRssi data.Rssi
        | SoilPhData data -> Option.map fromRssi data.Rssi
        | AverageWindSpeedData data -> Option.map fromRssi data.Rssi
        | WeatherStationData data -> Option.map fromRssi data.Rssi
        | LiquidLevelData data -> Option.map fromRssi data.Rssi
        | SolarRadiationData data -> Option.map fromRssi data.Rssi

    let toImageSrc (strength: T) =
        let fileName =
            match strength with
            | Unknown -> "sgrau"
            | VeryBad -> "s0"
            | Bad -> "s1"
            | Ok -> "s2"
            | Good -> "s3"
            | VeryGood -> "s4"

        sprintf "/signal-strength/%s.svg" fileName