module Client.Page.Admin.MapSensorList

open Client
open Client.Components.Select
open Client.Domain
open Client.DomainTypes
open Client.Infrastructure
open Client.Infrastructure.Api
open Client.DomainTypes.Msg

open Client.Forms
open Elmish
open Fable.FontAwesome
open Fable.React.Props
open Fulma
open Fulma.Extensions.Wikiki
open Shared
open Shared.Dto
open Shared.Dto.Dto
open Shared.DtoTypes.PhysicalSensor
open Shared.Infrastructure
open Fable.React
open Shared.Dto.User
open Shared.WithLastDate
open Thoth.Elmish
open System
open Zanaptak.TypedCssClasses

Fable.Core.JsInterop.importAll "bulma-tooltip"

type FA =
    CssClasses<"https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css", Naming.PascalCase>

type Modal =
    | ChoosePhysicalSensor of ChoosePhysicalSensor.Model
    | MapSensor of MapSensor.Model


type DataModel = {
    MapSensors: WithOptionalLastDate<IdValue<MapSensorDto>> list
    FilteredMapSensors: WithOptionalLastDate<IdValue<MapSensorDto>> list
    TtnSensors: ConfiguredPhysicalSensor list
    Users: UserDto list
    SelectedUserFilter: UserDto option
    Modal: Modal option
    Session: UserSession
}

type LoadingModel = {
    Session: UserSession
    MySensSensors: WithOptionalLastDate<IdValue<MapSensorDto>> list option
    TtnSensors: ConfiguredPhysicalSensor list option
    Users: UserDto list option
}

let modelLoadingToData (model: LoadingModel) : DataModel option =
    Option.map3
        (fun ttn mapSensors users -> {
            MapSensors = mapSensors
            FilteredMapSensors = mapSensors
            Session = model.Session
            TtnSensors = ttn
            Users = users
            SelectedUserFilter = None
            Modal = None
        })
        model.TtnSensors
        model.MySensSensors
        model.Users

type Model = Loadable<DataModel, LoadingModel>

let init (session: UserSession) =
    let requestData = {
        SessionKey = session.SessionKey
        Data = ()
    }

    let cmds =
        Cmd.batch [
            Cmd.OfAsync.perform
                api.getConfiguredTtnSensors
                ()
                (MapSensorListMsg.PhysicalSensorListReceived >> MapSensorList)
            Cmd.OfAsync.perform
                api.getAllMySensSensorsWithOptionalLastDate
                ()
                (MapSensorListMsg.MapSensorListReceived >> MapSensorList)
            Cmd.OfAsync.perform api.getAllUsers requestData (MapSensorListMsg.UsersReceived >> MapSensorList)
        ]

    Loadable.Loading {
        MySensSensors = None
        Session = session
        TtnSensors = None
        Users = None
    },
    cmds

let updateRequestRunningModalState (model: DataModel) (requestRunning: bool) =
    let newModalModel =
        match model.Modal with
        | Some(MapSensor modalModel) ->
            let newModel = { modalModel with RequestRunning = requestRunning }

            Some(MapSensor newModel)
        | _ -> model.Modal

    { model with Modal = newModalModel }

let getSortKey (sensor: WithOptionalLastDate<IdValue<MapSensorDto>>) =
    (MapSensor.getBaseData sensor.Value.Value).Name

let private belongsToUser (user: UserDto) (sensor: MapSensorDto) =
    match (MapSensor.getBaseData sensor).UserId with
    | Some id -> id = user.Id
    | None -> false

let update (msg: MapSensorListMsg) (model: Model) : Model * Cmd<Msg> =
    match msg, model with
    | MapSensorListMsg.PhysicalSensorListReceived sensors, Loadable.Loading loadingModel ->
        let updatedLoadingModel = { loadingModel with TtnSensors = Some sensors }

        let newModel =
            modelLoadingToData updatedLoadingModel
            |> Option.map Loadable.Data
            |> Option.defaultValue (Loadable.Loading updatedLoadingModel)

        newModel, Cmd.none
    | MapSensorListMsg.MapSensorListReceived sensors, Loadable.Loading loadingModel ->
        let updatedLoadingModel = { loadingModel with MySensSensors = Some(List.sortBy getSortKey sensors) }

        let newModel =
            modelLoadingToData updatedLoadingModel
            |> Option.map Loadable.Data
            |> Option.defaultValue (Loadable.Loading updatedLoadingModel)

        newModel, Cmd.none
    | MapSensorListMsg.UsersReceived maybeUsers, Loadable.Loading loadingModel ->
        match maybeUsers with
        | Result.Ok users ->
            let updatedLoadingModel = { loadingModel with Users = Some users }

            let newModel =
                modelLoadingToData updatedLoadingModel
                |> Option.map Loadable.Data
                |> Option.defaultValue (Loadable.Loading updatedLoadingModel)

            newModel, Cmd.none
        | Result.Error msg ->
            let errorMessage =
                match msg with
                | AuthErr Unauthenticated -> "Sie sind nicht angemeldet? Laden Sie die Seite neu"
                | AuthErr Unauthorized -> "Sie düfen nicht die Kartensensoren auflisten"
                | CustomErr error -> error

            let toastCmd =
                Toast.create errorMessage |> Toast.title "Fehler beim User laden" |> Toast.error

            model, toastCmd

    | MapSensorListMsg.CloseModal, Loadable.Data data -> Loadable.Data { data with Modal = None }, Cmd.none
    | MapSensorListMsg.OpenModal maybeSensor, Loadable.Data data ->
        let modal, cmd =
            match maybeSensor with
            | Some sensor ->
                let modalModel = MapSensor.initExisting data.TtnSensors data.Users sensor

                MapSensor modalModel, Cmd.none
            | None ->
                let modalModel, cmd = ChoosePhysicalSensor.init data.Session

                ChoosePhysicalSensor modalModel, cmd

        Loadable.Data { data with Modal = Some modal },

        Cmd.map MapSensorList cmd
    | MapSensorListMsg.SensorUpdated response, Loadable.Data data ->
        match response with
        | Ok(Some updatedSensor) ->
            let toastCmd = Toast.create "Der Sensor wurde erfolgreich geändert" |> Toast.success

            let newSensorList =
                List.filteredMap
                    (fun (sensor: WithOptionalLastDate<IdValue<MapSensorDto>>) -> sensor.Value.Id = updatedSensor.Id)
                    (fun sensor -> { sensor with Value = updatedSensor })
                    data.MapSensors

            Loadable.Data {
                data with
                    MapSensors = newSensorList
                    Modal = None
            },
            toastCmd
        | _ ->
            let toastCmd = Toast.create "Die Änderung ist fehlgeschlagen" |> Toast.error

            Loadable.Data(updateRequestRunningModalState data false), toastCmd
    | MapSensorListMsg.SensorCreated response, Loadable.Data data ->
        match response with
        | Ok(Some newSensor) ->
            let toastCmd = Toast.create "Der Sensor wurde erfolgreich erstellt" |> Toast.success

            let newSensor = {
                LastDate = None
                Value = newSensor
            }

            let newSensorList =
                List.sortBy
                    (fun (sensor: WithOptionalLastDate<IdValue<MapSensorDto>>) ->
                        (MapSensor.getBaseData sensor.Value.Value).Name
                    )
                    (newSensor :: data.MapSensors)

            Loadable.Data {
                data with
                    MapSensors = newSensorList
                    Modal = None
            },
            toastCmd
        | _ ->
            let toastCmd = Toast.create "Das Erstellen ist fehlgeschlagen" |> Toast.error

            Loadable.Data(updateRequestRunningModalState data false), toastCmd
    | MapSensorListMsg.UpdateSensor sensor, Loadable.Data data ->
        let requestData = {
            Data = sensor
            SessionKey = data.Session.SessionKey
        }

        Loadable.Data(updateRequestRunningModalState data true),
        Cmd.OfAsync.perform api.updateMapSensor requestData (MapSensorListMsg.SensorUpdated >> MapSensorList)
    | MapSensorListMsg.CreateSensor newSensor, Loadable.Data data ->
        let requestData = {
            Data = newSensor
            SessionKey = data.Session.SessionKey
        }

        Loadable.Data(updateRequestRunningModalState data true),
        Cmd.OfAsync.perform api.createMapSensor requestData (MapSensorListMsg.SensorCreated >> MapSensorList)
    | MapSensorListMsg.DeleteSensor id, _ ->
        model,
        Cmd.OfAsync.perform
            api.deleteMySensSensor
            id
            ((fun success -> id, success) >> MapSensorListMsg.SensorDeleted >> MapSensorList)
    | MapSensorListMsg.SensorDeleted(deletedId, success), Loadable.Data data ->
        if success then
            let toastCmd = Toast.create "Sensor erfolgreich gelöscht" |> Toast.success

            let newSensorList =
                List.filter
                    (fun (sensor: WithOptionalLastDate<IdValue<_>>) -> sensor.Value.Id <> deletedId)
                    data.MapSensors

            Loadable.Data {
                data with
                    MapSensors = newSensorList
                    Modal = None
            },
            toastCmd
        else
            let toastCmd = Toast.create "Fehler beim Löschen des Sensors" |> Toast.error

            model, toastCmd
    | MapSensorListMsg.ChoosePhysicalSensorForm choosePhysicalSensorFormMsg, Loadable.Data data ->
        match choosePhysicalSensorFormMsg, data.Modal with
        | Forward sensor, _ ->
            let model = MapSensor.initNew data.TtnSensors data.Users sensor

            Loadable.Data { data with Modal = Some(MapSensor model) }, Cmd.none
        | _, Some(ChoosePhysicalSensor modalData) ->
            ChoosePhysicalSensor.update choosePhysicalSensorFormMsg modalData
            |> fun (modal, cmd) ->
                Loadable.Data { data with Modal = Some(ChoosePhysicalSensor modal) }, Cmd.map MapSensorList cmd
        | _, _ -> model, Cmd.none
    | MapSensorListMsg.UserFilterChanged userFilter, Loadable.Data dataModel ->
        let filteredSensors =
            match userFilter with
            | Some user ->
                dataModel.MapSensors
                |> List.filter (fun sensor -> belongsToUser user sensor.Value.Value)
            | None -> dataModel.MapSensors

        Loadable.Data {
            dataModel with
                SelectedUserFilter = userFilter
                FilteredMapSensors = filteredSensors
        },
        Cmd.none
    | _, Loadable.Data data ->
        let result =
            match data.Modal with
            | Some(MapSensor modal) -> MapSensor.update msg modal |> (fun (modal, cmd) -> Some(MapSensor modal), cmd)
            | _ -> None, Cmd.none

        Loadable.Data { data with Modal = fst result }, snd result
    | _, _ -> model, Cmd.none

let private getTotalMinutesLimit (sensorType: SensorModel) =
    match sensorType with
    | WSC1L -> 15.0
    | LHT65
    | LSN50v2_S31
    | S31_LS
    | S31_LB
    | LSN50v2_Rain_01mm
    | LSN50v2_Rain_02mm
    | SN50v3_LS_Rain_01mm
    | SN50v3_LS_Rain_02mm
    | SN50v3_LB_Rain_01mm
    | SN50v3_LB_Rain_02mm
    | LSN50v2_Wind
    | SN50v3_LS_Wind
    | SN50v3_LB_Wind
    | DDS20_LB
    | DDS20_LS -> 30.0
    | LSE01
    | SE01_LS
    | SE01_LB
    | LLMS01
    | LSPH01
    | RS485_LB_ZFS_02
    | RS485_LS_ZFS_02 -> 50.0

let getIconForSensor
    (maybeTtnSensor: ConfiguredPhysicalSensor option)
    (sensor: WithOptionalLastDate<IdValue<MapSensorDto>>)
    =
    let toolTip, icon =
        match maybeTtnSensor with
        | Some ttnSensor ->
            let totalTimeLimit = getTotalMinutesLimit ttnSensor.Configuration

            let isOldAge =
                match sensor.LastDate with
                | None -> true
                | Some lastDate ->
                    let elapsedMinutes = (DateTimeOffset.UtcNow - lastDate).TotalMinutes

                    elapsedMinutes > totalTimeLimit

            match (MapSensor.getBaseData sensor.Value.Value).State, isOldAge with
            | Disabled, _ -> Some "Sensor ist deaktiviert", Fa.Solid.Ban
            | Active, true -> Some "Daten sind veraltet", Fa.Solid.Hourglass
            | Active, false -> None, Fa.Solid.Check
            | Defective, _ -> Some "Sensor ist defekt", Fa.Solid.ExclamationCircle
        | None -> Some "Kein physikalischer Sensor zugeordnet", Fa.Solid.QuestionCircle


    td [
        match toolTip with
        | Some text ->
            classList [ (Tooltip.ClassName, true) ]
            Tooltip.dataTooltip text
        | None -> ()
    ] [ Fa.span [ icon ] [] ]

let private createDropdownItem (dispatch: Msg -> unit) (icon: Fa.IconOption) (label: string) (onClickMsg: Msg) =
    Dropdown.Item.a [
        Dropdown.Item.Props [
            Clickable.onClickPreventDefault (fun _ -> dispatch onClickMsg) |> OnClick
        ]
    ] [
        Icon.icon [ Icon.Size IsMedium ] [ Fa.i [ icon ] [] ]

        span [] [ str label ]
    ]

let private createSensorDropdownMenu
    (dispatch: Msg -> unit)
    (sensor: WithOptionalLastDate<IdValue<MapSensorDto>>)
    (position: Location)
    =
    let createItem = createDropdownItem dispatch

    let dropDownItems = [
        createItem Fa.Solid.Edit "Bearbeiten" (MapSensorListMsg.OpenModal(Some sensor.Value) |> MapSensorList)
        createItem
            Fa.Solid.Cog
            "Einstellungen"
            (Clickable.goToRouteMsg (Route.UserDefinedMapSensorProperties sensor.Value.Id))
        createItem Fa.Solid.Search "Detail-Seite" (Clickable.goToRouteMsg (Route.MySensSensor sensor.Value.Id))
        createItem Fa.Solid.Globe "Zur Karte" (Clickable.goToRouteMsg (Route.SensorMap(Some position)))
        createItem Fa.Solid.TrashAlt "Löschen" (MapSensorListMsg.DeleteSensor sensor.Value.Id |> MapSensorList)
    ]

    Dropdown.dropdown [ Dropdown.IsHoverable; Dropdown.IsRight ] [
        Dropdown.trigger [] [
            Button.button [] [
                Icon.icon [ Icon.Size IsSmall ] [ Fa.i [ Fa.Solid.Cog ] [] ]
            ]
        ]
        Dropdown.menu [] [ Dropdown.content [] (dropDownItems) ]
    ]

let sensorToRow
    dispatch
    (ttnSensors: ConfiguredPhysicalSensor list)
    (users: UserDto list)
    (index: int)
    (sensor: WithOptionalLastDate<IdValue<MapSensorDto>>)
    =
    let findSensor =
        fun selectedDeviceEui ->
            List.tryFind
                (fun (sensor: ConfiguredPhysicalSensor) -> sensor.BaseData.DeviceEui = selectedDeviceEui)
                ttnSensors

    let baseData = (MapSensor.getBaseData sensor.Value.Value)

    let ttnSensor = Option.map findSensor baseData.TtnSensorId |> Option.flatten

    let findUser =
        fun userId -> List.tryFind (fun (user: UserDto) -> user.Id = userId) users

    let icon = getIconForSensor ttnSensor sensor

    let lastDateString =
        sensor.LastDate
        |> Option.map TimeSpan.createTimeSinceString
        |> Option.defaultValue ""

    let lastAssignmentDateString =
        baseData.LastAssignmentDate
        |> Option.map DateTime.toDateString
        |> Option.map (sprintf " (%s)")
        |> Option.defaultValue ""

    let lastDateTooltip =
        sensor.LastDate
        |> Option.map (fun date -> date.LocalDateTime)
        |> Option.map (fun date -> sprintf "%s (%s)" (DateTime.toDayMonthString date) (DateTime.toTimeString date))
        |> Option.map Tooltip.dataTooltip

    let stateSinceString =
        baseData.StateSince |> DateTimeOffset.toLocalDateTime |> DateTime.toShortString

    let lastDateCellProps =
        [
            classList [
                (Tooltip.ClassName, (Option.isSome lastDateTooltip))
                ("last_date", true)
            ]
            :> IHTMLProp
            |> Some
            Option.map (fun date -> date :> IHTMLProp) lastDateTooltip
        ]
        |> List.choose id

    let sensorHasLocation =
        if baseData.Location.Latitude <> 0 && baseData.Location.Longitude <> 0 then
            "Ja"
        else
            "Nein"

    let physicalSensorName =
        ttnSensor
        |> Option.map (fun ttnSensor -> ttnSensor.BaseData.Name)
        |> Option.defaultValue ""

    tr [] [
        td [] [ Table.rowIndexString index ]
        icon
        td [] [ str baseData.Name ]
        td [] [ baseData.Note |> Option.defaultValue "" |> str ]
        td [] [ str sensorHasLocation ]
        td [] [ str (Bool.toUserString baseData.IsPublic) ]
        td lastDateCellProps [ str lastDateString ]
        td [] [ str physicalSensorName ]
        td [] [
            Option.map findUser baseData.UserId
            |> Option.flatten
            |> Option.map getFullName
            |> Option.defaultValue ""
            |> str
            str lastAssignmentDateString
        ]
        td [] [ str stateSinceString ]
        td [] [
            createSensorDropdownMenu dispatch sensor baseData.Location
        ]
    ]

let sensorListToTable
    dispatch
    (ttnSensors: ConfiguredPhysicalSensor list)
    (users: UserDto list)
    (sensors: WithOptionalLastDate<IdValue<MapSensorDto>> list)
    =
    Table.table [
        Table.CustomClass "table-auto"
        Table.IsBordered
        Table.IsFullWidth
        Table.IsStriped
        Table.Modifiers [
            Modifier.Spacing(Spacing.MarginBottom, Spacing.Is6)
        ]
    ] [
        thead [] [
            tr [] [
                th [] [ str "#" ]
                th [] []
                th [] [ str "Name" ]
                th [] [ str "Notizen" ]
                th [] [ str "Hat GPS-Position?" ]
                th [] [ str "Sensor ist öffentlich" ]
                th [ classList [ ("last_date", true) ] ] [ str "Letzte Aktual." ]
                th [] [ str "Physikalischer Sensor" ]
                th [] [ str "Benutzer" ]
                th [] [ str "Status seit" ]
                th [] []
            ]
        ]
        tbody [] (List.mapi (sensorToRow dispatch ttnSensors users) sensors)
    ]

let createNewSensorButton dispatch =
    Button.button [
        Button.Color IsLink
        Button.OnClick(fun _ -> dispatch (MapSensorList <| MapSensorListMsg.OpenModal None))
    ] [ str "Neuen Sensor erstellen" ]

let tableHeader dispatch (selectedUser: UserDto option) (users: UserDto list) =
    Level.level [] [
        Level.left [] [
            Level.item [] [
                form [] [
                    Field.div [] [
                        Label.label [] [ str "Nach Benutzer filtern" ]
                        Control.div [] [
                            UserSelect.view
                                (MapSensorListMsg.UserFilterChanged >> MapSensorList >> dispatch)
                                selectedUser
                                users
                        ]
                    ]
                ]
            ]
        ]
        Level.right [] [
            Level.item [] [
                Field.div [] [
                    Control.div [] [ createNewSensorButton dispatch ]
                ]
            ]
        ]
    ]

let private dataView dispatch (data: DataModel) =
    let closeModal = (fun _ -> dispatch (MapSensorListMsg.CloseModal |> MapSensorList))

    let table =
        sensorListToTable dispatch data.TtnSensors data.Users data.FilteredMapSensors

    let modal =
        match data.Modal with
        | Some(ChoosePhysicalSensor modal) -> ChoosePhysicalSensor.view dispatch closeModal modal
        | Some(MapSensor modal) -> MapSensor.view dispatch closeModal modal
        | None -> div [] []

    Container.container [ Container.IsFluid ] [
        Heading.h1 [] [ str "Karten Sensoren Liste" ]
        tableHeader dispatch data.SelectedUserFilter data.Users
        (Table.scrollableTable table)
        modal
    ]

let view (model: Model) dispatch = Loadable.view (dataView dispatch) model