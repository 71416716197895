module Client.Page.User.MyUserGroups

open Client.Domain
open Client.Infrastructure.Api
open Client.InfrastructureTypes
open Client.DomainTypes.Msg
open Elmish
open Fulma
open Fable.React.Helpers
open Fable.React.Standard
open Shared.Dto.Dto
open Shared.Dto.Page.User

type DataModel = { Groups: MyUserGroups.GroupListingData list }

type Model = Loadable<DataModel, UserSession>

let init (session: UserSession) =
    let request = {
        SessionKey = session.SessionKey
        Data = ()
    }

    Loadable.Loading session,
    Cmd.OfAsync.perform api.getMyUserGroups request (MyUserGroupsMsg.GroupsReceived >> MyUserGroups)

let update (msg: MyUserGroupsMsg) (model: Model) =
    match msg, model with
    | MyUserGroupsMsg.GroupsReceived response, Loadable.Loading _ ->
        match response with
        | Ok groups -> Loadable.Data { Groups = groups }, Cmd.none
        | _ -> Loadable.Error "Beim Laden der Gruppen ist ein Fehler aufgetreten", Cmd.none
    | _, _ -> model, Cmd.none

let private groupRow (group: MyUserGroups.GroupListingData) =
    Columns.columns [] [
        Column.column [] [
            span [ Html.label ] [ str "Gruppe:" ]
            span [ Html.bold ] [ str group.GroupName ]
        ]
    ]

let private noGroups =
    Columns.columns [] [
        Column.column [] [
            span [] [
                str "Du bist kein Mitglied einer Benutzergruppe"
            ]
        ]
    ]


let private dataView (model: DataModel) =
    let rows =
        if List.isEmpty model.Groups then
            [ noGroups ]
        else
            List.map groupRow model.Groups

    Container.container [ Container.isFullWidth ] [
        Box.box' [] [
            Heading.p [] [ str "Meine Gruppen" ]

            yield! rows
        ]
    ]

let view (model: Model) = Loadable.view dataView model