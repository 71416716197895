module Client.Page.Admin.GatewayList

open Client
open Client.Infrastructure.Api
open Client.Components
open Client.Domain
open Client.Infrastructure
open Client.InfrastructureTypes
open Client.DomainTypes.Msg

open Client.Forms
open Elmish
open Fable.React.Props
open Fulma
open Fulma.Extensions.Wikiki
open Shared
open Shared.Dto.Dto
open Fable.React
open Shared.Dto.Gateways
open Shared.Dto.MySensGateway
open Shared.Dto.User
open Shared.Infrastructure
open Thoth.Elmish

type DataModel = {
    Gateways: GatewayWithStatus list
    Users: UserDto list
    Modal: Gateway.Model option
    TTNRefreshRequestRunning: bool
    TTIRefreshRequestRunning: bool
    Session: UserSession
}

type LoadingModel = {
    Session: UserSession
    Gateways: GatewayWithStatus list option
    Users: UserDto list option
}

let modelLoadingToData (model: LoadingModel) : DataModel option =
    Option.map2
        (fun gateways users -> {
            Gateways = gateways
            Session = model.Session
            Users = users
            Modal = None
            TTNRefreshRequestRunning = false
            TTIRefreshRequestRunning = false
        })
        model.Gateways
        model.Users

type Model = Loadable<DataModel, LoadingModel>

let init (session: UserSession) =
    let request = {
        SessionKey = session.SessionKey
        Data = ()
    }

    let cmds =
        Cmd.batch [
            Cmd.OfAsync.perform api.getAllGatewaysWithStatus () (GatewayListMsg.GatewaysReceived >> GatewayList)
            Cmd.OfAsync.perform api.getAllUsers request (GatewayListMsg.UsersReceived >> GatewayList)
        ]

    Loadable.Loading {
        Gateways = None
        Session = session
        Users = None
    },
    cmds

let updateRequestRunningModalState (model: DataModel) (requestRunning: bool) =
    let modalModel = Option.get model.Modal

    { model with Modal = Some { modalModel with RequestRunning = requestRunning } }

let updateRefreshRequestRunning (model: DataModel) (running: bool) = {
    model with
        TTNRefreshRequestRunning = running
        TTIRefreshRequestRunning = running
}

let update (msg: GatewayListMsg) (model: Model) : Model * Cmd<Msg> =
    match msg, model with
    | GatewayListMsg.GatewaysReceived gateways, Loadable.Loading loadingModel ->
        let updatedLoadingModel = { loadingModel with Gateways = Some gateways }

        let newModel =
            modelLoadingToData updatedLoadingModel
            |> Option.map Loadable.Data
            |> Option.defaultValue (Loadable.Loading updatedLoadingModel)

        newModel, Cmd.none
    | GatewayListMsg.UsersReceived maybeUsers, Loadable.Loading loadingModel ->
        match maybeUsers with
        | Result.Ok users ->
            let updatedLoadingModel = { loadingModel with Users = Some users }

            let newModel =
                modelLoadingToData updatedLoadingModel
                |> Option.map Loadable.Data
                |> Option.defaultValue (Loadable.Loading updatedLoadingModel)

            newModel, Cmd.none
        | Result.Error error ->
            let errorMessage =
                match error with
                | AuthErr Unauthenticated -> "Sie sind nicht eingeloggt, bitte laden Sie die Seite neu"
                | AuthErr Unauthorized ->
                    "Sie sind nicht dazu berechtigt, die Sensorkarte anzuzeigen, bitte wenden Sie sich an einen Administrator"
                | _ -> "Ein Fehler beim Laden der Sensoren ist aufgetreten. Bitte laden Sie die Seite neu"

            let toastCmd =
                Toast.create errorMessage |> Toast.title "Fehler beim User laden" |> Toast.error

            model, toastCmd
    | GatewayListMsg.RefreshGatewaysTTN, Loadable.Data data ->
        let cmd =
            Cmd.OfAsync.perform api.refreshGatewaysFromTtn () (GatewayListMsg.GatewaysRefreshed >> GatewayList)

        Loadable.Data { data with TTNRefreshRequestRunning = true }, cmd
    | GatewayListMsg.RefreshGatewaysTTI, Loadable.Data data ->
        let cmd =
            Cmd.OfAsync.perform api.refreshGatewaysFromTti () (GatewayListMsg.GatewaysRefreshed >> GatewayList)

        Loadable.Data { data with TTIRefreshRequestRunning = true }, cmd
    | GatewayListMsg.GatewaysRefreshed successful, Loadable.Data data ->
        if successful then
            let toastCmd =
                Toast.create "Die Gateways wurden erfolgreich aktualisiert" |> Toast.success

            init data.Session |> Cmds.batch toastCmd
        else
            let toastCmd =
                Toast.create "Ein Fehler ist aufgetreten beim aktualisieren der Gateways"
                |> Toast.error

            Loadable.Data(updateRefreshRequestRunning data false), toastCmd


    | GatewayListMsg.CloseModal, Loadable.Data data -> Loadable.Data { data with Modal = None }, Cmd.none
    | GatewayListMsg.OpenModal gateway, Loadable.Data data ->
        Loadable.Data { data with Modal = Some(Gateway.init data.Users gateway) }, Cmd.none
    | GatewayListMsg.GatewayUpdated success, Loadable.Data data ->
        if success then
            let toastCmd =
                Toast.create "Der Gateway wurde erfolgreich gespeichert" |> Toast.success

            init data.Session |> Cmds.batch toastCmd
        else
            let toastCmd = Toast.create "Das Speichern ist fehlgeschlagen" |> Toast.error

            Loadable.Data(updateRequestRunningModalState data false), toastCmd
    | GatewayListMsg.UpdateGateway gateway, Loadable.Data data ->
        Loadable.Data(updateRequestRunningModalState data true),
        Cmd.OfAsync.perform api.updateGateway gateway (GatewayListMsg.GatewayUpdated >> GatewayList)
    | _, Loadable.Data data ->
        let result =
            match data.Modal with
            | Some modal -> Gateway.update msg modal |> (fun (modal, cmd) -> Some modal, cmd)
            | None -> None, Cmd.none

        Loadable.Data { data with Modal = fst result }, snd result
    | _, _ -> model, Cmd.none

let maybeFloatToString (maybeFloat: float option) =
    maybeFloat
    |> Option.map (fun float -> float.ToString())
    |> Option.defaultValue ""

let private createStatusCell (status: GatewayStatus option) =
    let text, statusDot, tooltip =
        match status with
        | Some(Connected connected) ->
            let tooltip =
                sprintf "Seit %s " (DateTime.toShortString connected.ConnectedAt.LocalDateTime)

            "Verbunden", StatusDot.Green, tooltip
        | Some(Disconnected disconnected) ->
            let tooltip =
                match disconnected.DisconnectedAt with
                | Some disconnectedAt -> sprintf "Seit %s " (DateTime.toShortString disconnectedAt.LocalDateTime)
                | None -> "Schon seit längerem (noch nie?) gibt es keine Verbindung zum Gateway"

            "Keine Verbindung", StatusDot.Red, tooltip
        | None -> "Fehler", StatusDot.Grey, "Fehler beim Laden des Gateway Status"

    td [] [
        div [
            classList [
                (Tooltip.ClassName, true)
                ("device-status", true)
            ]
            Tooltip.dataTooltip tooltip
        ] [
            p [] [ str text ]
            img [
                Style [ Height "10px" ]
                Src(StatusDot.toImageUrl statusDot)
            ]
        ]
    ]

let gatewayToRow dispatch (users: UserDto list) (index: int) (gateway: GatewayWithStatus) =
    let findUser =
        fun userId -> List.tryFind (fun (user: UserDto) -> user.Id = userId) users

    tr [] [
        td [] [ Table.rowIndexString index ]
        td [] [ str gateway.Gateway.TtnId ]
        td [] [ str gateway.Gateway.Eui ]
        td [] [ str gateway.Gateway.Name ]
        td [] [
            str (gateway.Gateway.Description |> Option.defaultValue "")
        ]
        td [] [
            str (maybeFloatToString gateway.Gateway.Latitude)
        ]
        td [] [
            str (maybeFloatToString gateway.Gateway.Longitude)
        ]
        (createStatusCell gateway.Status)
        td [] [
            Option.map findUser gateway.Gateway.UserId
            |> Option.flatten
            |> Option.map (fun user -> getFullName user)
            |> Option.defaultValue ""
            |> str
        ]
        td [] [
            Button.button [
                Button.OnClick(fun _ ->
                    dispatch (
                        GatewayListMsg.OpenModal {
                            Id = gateway.Id
                            Value = gateway.Gateway
                        }
                        |> GatewayList
                    )
                )
            ] [ str "Bearbeiten" ]
        ]
    ]

let gatewayListToTable dispatch (gateways: GatewayWithStatus list) (users: UserDto list) =
    Table.table [
        Table.IsBordered
        Table.IsFullWidth
        Table.IsStriped
    ] [
        thead [] [
            tr [] [
                th [] [ str "#" ]
                th [] [ str "TTS Id" ]
                th [] [ str "EUI" ]
                th [] [ str "Name" ]
                th [] [ str "Beschreibung" ]
                th [] [ str "Breitengrad" ]
                th [] [ str "Längengrad" ]
                th [ classList [ ("last_date", true) ] ] [ str "Status" ]
                th [] [ str "Zugeordneter Benutzer" ]
                th [] []
            ]
        ]
        tbody
            []
            (gateways
             |> List.sortBy (fun gw -> gw.Gateway.Name)
             |> List.mapi (gatewayToRow dispatch users))
    ]

let refreshDevicesTTNButton isRefreshRequestRunning dispatch =
    Button.button [
        Button.IsLoading isRefreshRequestRunning
        Button.Color IsLink
        Button.OnClick(fun _ -> dispatch (GatewayList GatewayListMsg.RefreshGatewaysTTN))
    ] [ str "Gateways aus TTN aktualisieren" ]

let refreshDevicesTTIButton isRefreshRequestRunning dispatch =
    Button.button [
        Button.IsLoading isRefreshRequestRunning
        Button.Color IsLink
        Button.OnClick(fun _ -> dispatch (GatewayList GatewayListMsg.RefreshGatewaysTTI))
    ] [ str "Gateways aus TTI aktualisieren" ]

let tableHeader dispatch model =
    Level.level [] [
        Level.left [] []
        Level.right [] [
            Level.item [] [
                Field.div [] [
                    Control.div [] [
                        refreshDevicesTTNButton model.TTNRefreshRequestRunning dispatch
                    ]
                ]
            ]
            Level.item [] [
                Field.div [] [
                    Control.div [] [
                        refreshDevicesTTIButton model.TTIRefreshRequestRunning dispatch
                    ]
                ]
            ]
        ]
    ]

let private dataView dispatch (data: DataModel) =
    let table = gatewayListToTable dispatch data.Gateways data.Users

    let modal =
        data.Modal
        |> Option.map (Gateway.view dispatch)
        |> Option.defaultValue (div [] [])

    Container.container [ Container.IsFluid ] [
        Heading.h1 [] [ str "MySens Gateway Liste" ]
        tableHeader dispatch data
        Table.scrollableTable table
        modal
    ]

let view (model: Model) dispatch = Loadable.view (dataView dispatch) model