module Client.Page.Admin.PhysicalSensorList

open System
open Client.Components
open Client.Domain
open Client.Infrastructure
open Client.InfrastructureTypes
open Client
open Client.Infrastructure.Api
open Client.DomainTypes.Msg

open Elmish
open Fable.React.Props
open Fulma
open Shared.Dto.Dto
open Fable.React
open Shared.DtoTypes
open Shared.DtoTypes.PhysicalSensor
open Shared.Infrastructure
open Thoth.Elmish

type DataModel = {
    Sensors: PhysicalSensorWithLastData list
    TTNRefreshButton: PhysicalSensorRefreshButton.Model
    TTIRefreshButton: PhysicalSensorRefreshButton.Model
    Session: UserSession
    Modal: Forms.PhysicalSensor.Model option
}

type Model = Loadable<DataModel, UserSession>

let init session =
    (Loadable.Loading session,
     Cmd.OfAsync.perform api.getAllTtnSensors () (PhysicalSensorListMsg.ListReceived >> TtnSensorList))

let updateRequestRunning (model: DataModel) (requestRunning: bool) =
    let modalModel = Option.get model.Modal

    { model with Modal = Some { modalModel with RequestRunning = requestRunning } }

let private updateRefreshButtonState
    (f: PhysicalSensorRefreshButton.Model -> DataModel -> DataModel)
    (result: PhysicalSensorRefresh.Result)
    (model: DataModel)
    : Model * Cmd<'msg> =
    let newModel, buttonState =
        match result with
        | PhysicalSensorRefresh.RefreshFailed message ->
            model, PhysicalSensorRefreshButton.Finished(PhysicalSensorRefreshButton.FinishedState.Error message)
        | PhysicalSensorRefresh.Refreshed result ->
            { model with Sensors = result.Sensors }, PhysicalSensorRefreshButton.modelFromResult result

    newModel |> f buttonState |> Loadable.Data, Cmd.none

let update (msg: PhysicalSensorListMsg) (model: Model) =
    match msg, model with
    | PhysicalSensorListMsg.RefreshTTNSensors, Loadable.Data data ->
        let request = {
            SessionKey = data.Session.SessionKey
            Data = ()
        }

        let cmd =
            Cmd.OfAsync.perform
                api.refreshTtnEndDevices
                request
                (PhysicalSensorListMsg.TtnListRefreshed >> TtnSensorList)

        Loadable.Data { data with TTNRefreshButton = PhysicalSensorRefreshButton.RefreshRunning }, cmd
    | PhysicalSensorListMsg.RefreshTTISensors, Loadable.Data data ->
        let request = {
            SessionKey = data.Session.SessionKey
            Data = ()
        }

        let cmd =
            Cmd.OfAsync.perform
                api.refreshTtiEndDevices
                request
                (PhysicalSensorListMsg.TtiListRefreshed >> TtnSensorList)

        Loadable.Data { data with TTIRefreshButton = PhysicalSensorRefreshButton.RefreshRunning }, cmd
    | PhysicalSensorListMsg.TtiListRefreshed result, Loadable.Data dataModel ->
        let updater =
            fun buttonState dataModel -> { dataModel with TTIRefreshButton = buttonState }

        updateRefreshButtonState updater result dataModel

    | PhysicalSensorListMsg.TtnListRefreshed result, Loadable.Data dataModel ->
        let updater =
            fun buttonState dataModel -> { dataModel with TTNRefreshButton = buttonState }

        updateRefreshButtonState updater result dataModel

    | PhysicalSensorListMsg.ListReceived sensors, Loadable.Loading session ->
        Loadable.Data {
            Sensors = sensors
            Session = session
            Modal = None
            TTNRefreshButton = PhysicalSensorRefreshButton.Waiting
            TTIRefreshButton = PhysicalSensorRefreshButton.Waiting
        },
        Cmd.none
    | PhysicalSensorListMsg.CloseModal, Loadable.Data data -> Loadable.Data { data with Modal = None }, Cmd.none
    | PhysicalSensorListMsg.OpenNewSensorModal, Loadable.Data data ->
        Loadable.Data { data with Modal = Some(Forms.PhysicalSensor.init None) }, Cmd.none
    | PhysicalSensorListMsg.EditSensorModal sensor, Loadable.Data data ->
        Loadable.Data { data with Modal = Some(Forms.PhysicalSensor.init (Some sensor)) }, Cmd.none
    | PhysicalSensorListMsg.Form formMsg, Loadable.Data data ->
        match (formMsg, data.Modal) with
        | PhysicalSensorFormMsg.SelectedSensorModelChanged selectedType, Some modal ->
            let modalModel = { modal with SelectedType = selectedType }

            Loadable.Data { data with Modal = Some modalModel }, Cmd.none
        | PhysicalSensorFormMsg.NameChanged name, Some modal ->
            let modalModel = { modal with Name = name }

            Loadable.Data { data with Modal = Some modalModel }, Cmd.none
        | PhysicalSensorFormMsg.AppEuiChanged appEui, Some modal ->
            let modalModel = { modal with AppEui = appEui }

            Loadable.Data { data with Modal = Some modalModel }, Cmd.none
        | PhysicalSensorFormMsg.DeviceEuiChanged deviceEui, Some modal ->
            let modalModel = { modal with DeviceEui = deviceEui }

            Loadable.Data { data with Modal = Some modalModel }, Cmd.none
        | PhysicalSensorFormMsg.AppKeyChanged appKey, Some modal ->
            let modalModel = { modal with AppKey = appKey }

            Loadable.Data { data with Modal = Some modalModel }, Cmd.none
        | PhysicalSensorFormMsg.DescriptionChanged description, Some modal ->
            let modalModel = { modal with Description = description }

            Loadable.Data { data with Modal = Some modalModel }, Cmd.none
        | _ -> Loadable.Data data, Cmd.none


    | PhysicalSensorListMsg.CreateSensor sensor, Loadable.Data data ->
        let request = {
            SessionKey = data.Session.SessionKey
            Data = sensor
        }

        Loadable.Data(updateRequestRunning data true),

        Cmd.map TtnSensorList (Cmd.OfAsync.perform api.createPhysicalSensor request PhysicalSensorListMsg.SensorCreated)
    | PhysicalSensorListMsg.SensorCreated response, Loadable.Data data ->
        match response with
        | Ok(Some createdSensor) ->
            let toastCmd =
                Toast.create "Der Sensor wurde erfolgreich gespeichert" |> Toast.success

            let createdSensorWithDate = {
                Sensor = Configured createdSensor
                LastData = None
            }

            let newList =
                createdSensorWithDate :: data.Sensors
                |> List.sortBy (fun sensor -> (getBaseData sensor.Sensor).Name)

            Loadable.Data {
                data with
                    Sensors = newList
                    Modal = None
            },
            toastCmd
        | _ ->
            let toastCmd =
                Toast.create "Ein Fehler ist aufgetreten beim Speichern des Sensors"
                |> Toast.error

            Loadable.Data(updateRequestRunning data false), toastCmd
    | PhysicalSensorListMsg.UpdateSensor sensor, Loadable.Data data ->
        let request = {
            SessionKey = data.Session.SessionKey
            Data = sensor
        }

        Loadable.Data(updateRequestRunning data true),

        Cmd.map TtnSensorList (Cmd.OfAsync.perform api.updatePhysicalSensor request PhysicalSensorListMsg.SensorUpdated)
    | PhysicalSensorListMsg.SensorUpdated response, Loadable.Data data ->
        match response with
        | Ok(Some updatedSensor) ->
            let toastCmd =
                Toast.create "Der Sensor wurde erfolgreich aktualisiert" |> Toast.success

            let newList =
                List.filteredMap
                    (fun (sensor: PhysicalSensorWithLastData) ->
                        (getBaseData sensor.Sensor).DeviceEui = updatedSensor.BaseData.DeviceEui
                    )
                    (fun (sensor: PhysicalSensorWithLastData) -> { sensor with Sensor = Configured updatedSensor })
                    data.Sensors

            Loadable.Data {
                data with
                    Sensors = newList
                    Modal = None
            },
            toastCmd
        | _ ->
            let toastCmd =
                Toast.create "Ein Fehler ist aufgetreten beim Speichern des Sensors"
                |> Toast.error

            Loadable.Data(updateRequestRunning data false), toastCmd
    | PhysicalSensorListMsg.DeleteSensor sensor, Loadable.Data data ->
        let request = {
            SessionKey = data.Session.SessionKey
            Data = sensor
        }

        Loadable.Data { data with Modal = None },
        Cmd.map TtnSensorList (Cmd.OfAsync.perform api.removePhysicalSensor request PhysicalSensorListMsg.SensorDeleted)
    | PhysicalSensorListMsg.SensorDeleted response, Loadable.Data data ->
        match response with
        | Ok _ ->
            let toastCmd = Toast.create "Der Sensor wurde erfolgreich gelöscht" |> Toast.success

            init data.Session |> Cmds.batch toastCmd
        | _ ->
            let toastCmd =
                Toast.create "Ein Fehler ist aufgetreten beim Lößchen des Sensors"
                |> Toast.error

            Loadable.Data(updateRequestRunning data false), toastCmd
    | _, _ -> model, Cmd.none

let private expectedIntervalMinutes (model: SensorModel) =
    match model with
    | WSC1L -> 2
    | LHT65
    | LSN50v2_S31
    | S31_LS
    | S31_LB
    | LSN50v2_Wind
    | SN50v3_LS_Wind
    | SN50v3_LB_Wind
    | LSN50v2_Rain_01mm
    | LSN50v2_Rain_02mm
    | SN50v3_LS_Rain_01mm
    | SN50v3_LS_Rain_02mm
    | SN50v3_LB_Rain_01mm
    | SN50v3_LB_Rain_02mm
    | DDS20_LB
    | DDS20_LS -> 10
    | LSE01
    | SE01_LS
    | SE01_LB
    | LLMS01
    | LSPH01
    | RS485_LB_ZFS_02
    | RS485_LS_ZFS_02 -> 20

let private dangerColor = "#ff0000"

let private blackColor = "#000000"

let private createTableCell (color: string) (content: string) =
    td [ Style [ Color color ] ] [ str content ]

let private createLastIntervalCell (sensorModel: SensorModel) (timespan: TimeSpan) =
    let expectedMinutes = expectedIntervalMinutes sensorModel

    let roundedMinutes = TimeSpan.toRoundedMinutes timespan

    let color =
        if roundedMinutes <> expectedMinutes then
            dangerColor
        else
            blackColor

    let content = TimeSpan.countToString roundedMinutes "Minute" "Minuten"

    createTableCell color content

let private expectedSpreadingFactor = 7

let lastDataCells (maybeSensorModel: SensorModel option) (lastData: PhysicalSensorLastData) : ReactElement list =
    let timeStampCell =
        lastData.LastTimeStamp
        |> TimeSpan.createElapsedTime
        |> TimeSpan.toHumanReadable
        |> sprintf "vor %s"
        |> fun content -> td [] [ str content ]

    let interval =
        match lastData.LastTimespan, maybeSensorModel with
        | Some interval, Some sensorModel -> createLastIntervalCell sensorModel interval
        | Some interval, None ->
            let roundedMinutes = TimeSpan.toRoundedMinutes interval

            let content = TimeSpan.countToString roundedMinutes "Minute" "Minuten"

            createTableCell blackColor content
        | None, _ -> td [] []

    let lastSpreadingFactorColor =
        if lastData.LastSpreadingFactor <> expectedSpreadingFactor then
            dangerColor
        else
            blackColor

    let content = sprintf "SF %d" lastData.LastSpreadingFactor

    let lastSpreadingFactor = createTableCell lastSpreadingFactorColor content

    [ timeStampCell; interval; lastSpreadingFactor ]

let sensorToRow dispatch (index: int) (sensor: PhysicalSensorWithLastData) =
    let lastDataCells =
        match sensor.LastData with
        | Some lastData -> lastDataCells (getSensorType sensor.Sensor) lastData
        | None -> [ td [] []; td [] []; td [] [] ]

    tr [] [
        td [] [ Table.rowIndexString index ]
        td [] [ str (getSensorName sensor.Sensor) ]
        td [] [ str (getSensorAppEui sensor.Sensor) ]
        td [] [ str (getSensorDeviceEui sensor.Sensor) ]
        td [] [ str (getSensorAppKey sensor.Sensor) ]
        td [] [
            (getBaseData sensor.Sensor).Description |> Option.defaultValue "" |> str
        ]
        td [] [
            sensorTypeAsString sensor.Sensor |> Option.defaultValue "-" |> str
        ]
        yield! lastDataCells
        td [] [
            Button.button [
                Clickable.onClickPreventDefault (fun _ ->
                    dispatch (PhysicalSensorListMsg.EditSensorModal sensor.Sensor |> TtnSensorList)
                )
                |> Button.OnClick
            ] [ str "Bearbeiten" ]
        ]
        td [] [
            Button.button [
                Clickable.onClickPreventDefault (fun _ ->
                    dispatch (PhysicalSensorListMsg.DeleteSensor(getSensorName sensor.Sensor) |> TtnSensorList)
                )
                |> Button.OnClick
            ] [ str "Löschen" ]
        ]
    ]

let sensorListToTable dispatch (sensors: PhysicalSensorWithLastData list) =
    Table.table [
        Table.IsBordered
        Table.IsFullWidth
        Table.IsStriped
    ] [
        thead [] [
            tr [] [
                td [] [ str "#" ]
                td [] [ str "Name" ]
                td [] [ str "App EUI" ]
                td [] [ str "Device EUI" ]
                td [] [ str "App key" ]
                td [] [ str "Description" ]
                td [] [ str "Sensortyp" ]
                td [] [ str "Letzte Daten" ]
                td [] [ str "Letztes Interval" ]
                td [] [ str "Letzte Datenrate" ]
                td [] []
                td [] []
            ]
        ]
        tbody [] (List.mapi (sensorToRow dispatch) sensors)
    ]

let createNewDevice dispatch =
    Button.button [
        Button.Color IsLink
        Button.OnClick(fun _ -> dispatch (TtnSensorList PhysicalSensorListMsg.OpenNewSensorModal))
    ] [ str "Neuer Sensor anlegen" ]

let refreshTtnButton model dispatch =
    PhysicalSensorRefreshButton.view
        "Sensor aus TTN herunterladen"
        (fun _ -> dispatch (TtnSensorList PhysicalSensorListMsg.RefreshTTNSensors))
        model

let refreshTtiButton model dispatch =
    PhysicalSensorRefreshButton.view
        "Sensor aus TTI herunterladen"
        (fun _ -> dispatch (TtnSensorList PhysicalSensorListMsg.RefreshTTISensors))
        model

let tableButtons ttnButtonModel ttiButtonModel dispatch =
    Level.level [] [
        Level.left [] []
        Level.right [] [
            Level.item [] [
                Field.div [] [ Control.div [] [ createNewDevice dispatch ] ]
            ]
            Level.item [] [
                Field.div [] [
                    Control.div [] [ refreshTtnButton ttnButtonModel dispatch ]
                ]
            ]
            Level.item [] [
                Field.div [] [
                    Control.div [] [ refreshTtiButton ttiButtonModel dispatch ]
                ]
            ]
        ]
    ]


let private dataView dispatch (data: DataModel) =
    let tableButtons = tableButtons data.TTNRefreshButton data.TTIRefreshButton dispatch
    let table = sensorListToTable dispatch data.Sensors

    let modal =
        data.Modal
        |> Option.map (Forms.PhysicalSensor.view (TtnSensorList >> dispatch))
        |> Option.defaultValue (div [] [])

    Container.container [ Container.IsFluid ] [
        Heading.h1 [] [ str "Physikalische Sensoren Liste" ]
        tableButtons
        Table.scrollableTable table
        modal
    ]


let view (model: Model) dispatch = Loadable.view (dataView dispatch) model